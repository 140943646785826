import { DateHelper } from "@/lib/dates";
import DateRangeControls from "@/ui-lib/components/DateRangeControls";
import Grid from "@/ui-lib/components/Grid";
import { useTheme } from "@emotion/react";
import {
  faChevronDown,
  faClose,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import {
  DateRange,
  getDateRangeFromDurationType,
} from "@ternary/api-lib/analytics/utils";
import { UnitType } from "@ternary/api-lib/constants/analytics";
import {
  ChartType,
  DataSource,
  DurationType,
  Operator,
  TimeGranularity,
} from "@ternary/api-lib/constants/enums";
import AreaChart from "@ternary/api-lib/ui-lib/charts/AreaChart";
import StackedBarChart from "@ternary/api-lib/ui-lib/charts/StackedBarChart";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import EmptyPlaceholder from "@ternary/api-lib/ui-lib/components/EmptyPlaceholder";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import useReferenceIfEqual from "@ternary/api-lib/ui-lib/hooks/useReferenceIfEqual";
import { formatDate } from "@ternary/api-lib/ui-lib/utils/dates";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import { differenceInHours, endOfDay } from "date-fns";
import { groupBy, noop } from "lodash";
import React, { useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import {
  DateParam,
  DecodedValueMap,
  createEnumParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import { z } from "zod";
import useGetRawData from "../../../../api/analytics/useGetRawData";
import InsightsSelector from "../../../../components/InsightsSelector";
import useAvailableGlobalDate from "../../../../hooks/useAvailableGlobalDate";
import { createStructParam } from "../../../../lib/use-query-params";
import Chip from "../../../../ui-lib/components/Chip";
import Dropdown from "../../../../ui-lib/components/Dropdown";
import Modal from "../../../../ui-lib/components/Modal";
import TextInput from "../../../../ui-lib/components/TextInput";
import IconExport from "../../../../ui-lib/icons/IconExport";
import copyText from "../../copyText";
import useGetAWSOpenSearchInstanceGroups from "../hooks/useGetAWSOpenSearchInstanceGroups";
import useGetAWSOpenSearchInstances from "../hooks/useGetAWSOpenSearchInstances";
import useGetAWSOpenSearchSpendSummaries from "../hooks/useGetAWSOpenSearchSpendSummaries";
import { awsOpenSearchDimensions, awsOpenSearchMeasures } from "../types";
import AWSOpenSearchInstanceGroupTable from "./AWSOpenSearchInstanceGroupTable";
import AWSOpenSearchInstancesTable from "./AWSOpenSearchInstancesTable";
import AWSOpenSearchMeters from "./AWSOpenSearchMeters";

const AWSOpenSearchCostChartOption = {
  USAGE_TYPE: "USAGE_TYPE",
  DATA_TRANSFER_DIRECTION: "DATA_TRANSFER_DIRECTION",
  USAGE_ACCOUNT_ID: "USAGE_ACCOUNT_ID",
  REGION: "REGION",
  DOMAIN_ID: "DOMAIN_ID",
  INSTANCE_TYPE: "INSTANCE_TYPE",
} as const;

type AWSOpenSearchCostChartOption =
  (typeof AWSOpenSearchCostChartOption)[keyof typeof AWSOpenSearchCostChartOption];

const AWSOpenSearchUsageChartOption = {
  DATA_TRANSFER: "DATA_TRANSFER",
  DOMAIN_ID: "DOMAIN_ID",
  INSTANCE_TYPE: "INSTANCE_TYPE",
} as const;

type AWSOpenSearchUsageChartOption =
  (typeof AWSOpenSearchUsageChartOption)[keyof typeof AWSOpenSearchUsageChartOption];

type Interaction = AWSOpenSearchInstanceGroupTable.Interaction;

type QueryParams = DecodedValueMap<typeof queryParamConfigMap>;

type QueryParamState = {
  dateRange: DateRange;
  dateRangeGranularity: TimeGranularity;
  duration: DurationType;
  instanceGroupFilters: z.infer<typeof instanceGroupFiltersStruct>;
  selectedCostChartOption: AWSOpenSearchCostChartOption;
  selectedUsageChartOption: AWSOpenSearchUsageChartOption;
};

const instanceGroupFiltersDefault = {
  domainId: null,
  usageAccountId: null,
  region: null,
};

const instanceGroupFiltersStruct = z.object({
  domainId: z.nullable(z.string()),
  usageAccountId: z.nullable(z.string()),
  region: z.nullable(z.string()),
});

const costChartOptionEnum = createEnumParam(
  Object.values(AWSOpenSearchCostChartOption)
);

const usageChartOptionEnum = createEnumParam(
  Object.values(AWSOpenSearchUsageChartOption)
);

const durationEnum = createEnumParam(Object.values(DurationType));

const queryParamConfigMap = {
  date_range_end: DateParam,
  date_range_start: DateParam,
  duration: withDefault(durationEnum, DurationType.LAST_THIRTY_DAYS),
  instance_group_filters: createStructParam(instanceGroupFiltersStruct),
  selected_cost_chart_option: withDefault(
    costChartOptionEnum,
    AWSOpenSearchCostChartOption.USAGE_TYPE
  ),
  selected_usage_chart_option: withDefault(
    usageChartOptionEnum,
    AWSOpenSearchUsageChartOption.DATA_TRANSFER
  ),
};

export default function AWSOpenSearchContainer() {
  const theme = useTheme();
  const globalDate = useAvailableGlobalDate();

  //
  // STATE
  //
  const [queryParams, setQueryParams] = useQueryParams(queryParamConfigMap);

  const queryParamState = useReferenceIfEqual(getQueryParamState(queryParams));

  const [searchText, setSearchText] = useState("");
  const [selectedInstanceGroupID, setSelectedInstanceGroupID] = useState<
    string | null
  >(null);

  //
  // QUERIES
  //

  const dateRange = globalDate.date ?? queryParamState.dateRange;

  const openSearchSpendSummary = useGetAWSOpenSearchSpendSummaries();

  const [{ data: currentMTD }, { data: lastMonthFull }, { data: lastMTD }] =
    openSearchSpendSummary;

  const isLoadingSpendSummaries = openSearchSpendSummary.some(
    (summary) => summary.isFetching
  );

  const costMeasures = getCostMeasuresFromOptions(
    queryParamState.selectedCostChartOption
  );

  const costDimensions = getCostDimensionsFromOptions(
    queryParamState.selectedCostChartOption
  );

  const { data: costChartData = [], isFetching: isLoadingCostChartData } =
    useGetRawData({
      dataSource: DataSource.AWS_OPEN_SEARCH_VISIBILITY,
      dateRange,
      dimensions: costDimensions,
      granularity: queryParamState.dateRangeGranularity,
      measures: costMeasures,
      queryFilters: getCostChartQueryFilters(
        queryParamState.selectedCostChartOption
      ),
    });

  const usageMeasures = getUsageMeasuresFromOption(
    queryParamState.selectedUsageChartOption
  );

  const usageDimensions = getUsageDimensionsFromOption(
    queryParamState.selectedUsageChartOption
  );

  const { data: usageChartData = [], isFetching: isLoadingUsageChartData } =
    useGetRawData({
      dataSource: DataSource.AWS_OPEN_SEARCH_VISIBILITY,
      dateRange,
      dimensions: usageDimensions,
      granularity: queryParamState.dateRangeGranularity,
      measures: usageMeasures,
      queryFilters: getUsageChartQueryFilters(
        queryParamState.selectedUsageChartOption
      ),
    });

  const { data: instanceGroups = [], isFetching: isLoadingInstanceGroups } =
    useGetAWSOpenSearchInstanceGroups({
      dateRange,
    });

  const { data: instances = [], isFetching: isLoadingInstances } =
    useGetAWSOpenSearchInstances({ dateRange });

  const instancesGroupedByDomainID = groupBy(
    instances,
    awsOpenSearchDimensions.domainId
  );

  //
  // INTERACTIONS
  //

  function handleInteraction(interaction: Interaction) {
    switch (interaction.type) {
      case AWSOpenSearchInstanceGroupTable.INTERACTION_FILTER_CLICKED: {
        const nextFilters = { ...queryParamState.instanceGroupFilters };

        nextFilters[interaction.filterKey] = interaction.filterValue;

        setQueryParams({ instance_group_filters: nextFilters });
        break;
        break;
      }
      case AWSOpenSearchInstanceGroupTable.INTERACTION_INSTANCE_CLICKED: {
        setSelectedInstanceGroupID(interaction.instanceGroupID);
      }
    }
  }

  const costChartReportSnapshot = {
    chartType: ChartType.STACKED_BAR,
    dateRange:
      queryParamState.duration === DurationType.CUSTOM || globalDate
        ? queryParamState.dateRange
        : null,
    dataSource: DataSource.AWS_OPEN_SEARCH_VISIBILITY,
    dimensions: costDimensions,
    durationType: queryParamState.duration,
    isFiscalMode: false,
    fillMissingDates: true,
    fiscalPeriodMap: null,
    granularity: queryParamState.dateRangeGranularity,
    measures: costMeasures,
    name: copyText.awsOpenSearchCostReportSnapshotName,
    xAxisKey: "timestamp",
  };

  const usageChartReportSnapshot = {
    chartType: ChartType.AREA,
    dateRange:
      queryParamState.duration === DurationType.CUSTOM || globalDate
        ? queryParamState.dateRange
        : null,
    dataSource: DataSource.AWS_OPEN_SEARCH_VISIBILITY,
    dimensions: usageDimensions,
    durationType: queryParamState.duration,
    isFiscalMode: false,
    fillMissingDates: true,
    fiscalPeriodMap: null,
    granularity: queryParamState.dateRangeGranularity,
    measures: usageMeasures,
    name: copyText.awsOpenSearchUsageReportSnapshotName,
    xAxisKey: "timestamp",
  };

  //
  // Render
  //

  const filteredTableData = useMemo(() => {
    let filteredData = instanceGroups;

    if (searchText.length > 0) {
      filteredData = instanceGroups.filter((group) => {
        const str = searchText.toLowerCase();

        const domainID = group.domainId.toLowerCase();
        const usageAccountID = group.usageAccountId?.toLowerCase();
        const region = group.region?.toLowerCase();

        return (
          domainID.includes(str) ||
          usageAccountID?.includes(str) ||
          region?.includes(str)
        );
      });
    }

    const paramFilters = queryParamState.instanceGroupFilters;

    if (Object.values(paramFilters).some((value) => value)) {
      filteredData = filteredData.filter((group) => {
        if (paramFilters.domainId) {
          return group.domainId
            .toLowerCase()
            .includes(paramFilters.domainId.toLowerCase());
        }
        if (paramFilters.region) {
          return group.region
            .toLowerCase()
            .includes(paramFilters.region.toLowerCase());
        }

        if (paramFilters.usageAccountId) {
          return group.usageAccountId
            .toLowerCase()
            .includes(paramFilters.usageAccountId.toLowerCase());
        }
      });
    }

    return filteredData;
  }, [instanceGroups, searchText, queryParamState]);

  function renderInstanceTableModal() {
    if (!selectedInstanceGroupID) return null;

    const instances = instancesGroupedByDomainID[selectedInstanceGroupID];

    const domainID = instances[0].domainId;
    const usageAccountID = instances[0].usageAccountId;

    return (
      <Modal
        isOpen
        showCloseButton
        onClose={() => setSelectedInstanceGroupID(null)}
        minWidth={1100}
      >
        <Modal.Header>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            marginLeft={theme.space_sm}
            width="100%"
          >
            <Box>
              <Text fontSize={theme.h4_fontSize}>
                {copyText.awsOpenSearchInstanceModalTitle.replace(
                  "%DOMAIN%",
                  domainID
                )}
              </Text>

              <Text appearance="h5" color={theme.text_color_secondary}>
                {copyText.awsOpenSearchInstanceModalSubtitle.replace(
                  "%ACCOUNT%",
                  usageAccountID
                )}
              </Text>
            </Box>
          </Flex>
        </Modal.Header>
        <Modal.Body>
          <AWSOpenSearchInstancesTable
            intances={instances}
            isLoading={isLoadingInstances}
          />
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <Box width="100%" paddingTop={theme.space_md}>
      <Box
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_2}
        marginBottom={theme.space_lg}
        padding={theme.space_md}
      >
        <AWSOpenSearchMeters
          isLoading={isLoadingSpendSummaries}
          lastMonthSpend={lastMonthFull?.totalCost ?? 0}
          lastMTDSpend={lastMTD?.totalCost ?? 0}
          thisMTDSpend={currentMTD?.totalCost ?? 0}
        />
      </Box>
      <Flex
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_1}
        justifyContent="flex-end"
        marginBottom={theme.space_lg}
        padding={theme.space_md}
      >
        <DateRangeControls
          dateRange={dateRange}
          durationType={queryParamState.duration}
          hiddenOptions={[
            DurationType.LAST_NINETY_DAYS,
            DurationType.QUARTER_TO_DATE,
            DurationType.YEAR_TO_DATE,
          ]}
          maxDate={new DateHelper().date}
          onChangeDateRange={(duration, newDateRange) => {
            setQueryParams({
              duration,
              ...(newDateRange && newDateRange[0] && newDateRange[1]
                ? {
                    date_range_start: newDateRange[0],
                    date_range_end: newDateRange[1],
                  }
                : {
                    date_range_start: null,
                    date_range_end: null,
                  }),
            });
          }}
        />
      </Flex>

      <Grid
        gridColumnGap={theme.space_lg}
        gridTemplateColumns={`repeat(2, calc(50% - (${theme.space_lg} / 2) ))`}
      >
        <InsightsSelector
          resourceName={copyText.awsComputeCostReportSnapshotName}
          reportSnapshot={costChartReportSnapshot}
        >
          <Flex
            backgroundColor={theme.panel_backgroundColor}
            borderRadius={theme.borderRadius_2}
            direction="column"
            height={500}
            padding={theme.space_md}
          >
            <Flex justifyContent="space-between" paddingBottom={theme.space_md}>
              <Text fontSize={theme.h3_fontSize}>
                {copyText.awsComputeOptimizationsChartTitleCost}
              </Text>

              {/* COST DROPDOWN */}
              <Dropdown
                options={costChartOptions.map((option) => ({
                  ...option,
                  onClick: () =>
                    setQueryParams({
                      selected_cost_chart_option: option.value,
                    }),
                }))}
                placement="bottom-end"
                selectedOption={
                  costChartOptions.find(
                    (option) =>
                      option.value === queryParamState.selectedCostChartOption
                  ) ?? costChartOptions[0]
                }
              >
                <Button
                  iconEnd={<Icon icon={faChevronDown} />}
                  secondary
                  size="small"
                  width={180}
                >
                  {getOptionLabel(queryParamState.selectedCostChartOption)}
                </Button>
              </Dropdown>
            </Flex>

            {/* COST CHART */}
            <Box flex="1 0 0">
              <StackedBarChart
                data={costChartData}
                dimensions={costDimensions.map((dimension) => ({
                  name: dimension,
                  isDate: false,
                }))}
                isLoading={isLoadingCostChartData}
                maxGroupings={7}
                measures={costMeasures.map((measure) => ({
                  name: measure,
                  unit: UnitType.CURRENCY,
                }))}
                showLegend
                showTooltip
                timeSeriesGranularity={queryParamState.dateRangeGranularity}
                xAxisKey="timestamp"
              />
            </Box>
          </Flex>
        </InsightsSelector>
        <InsightsSelector
          resourceName={copyText.awsComputeUsageReportSnapshotName}
          reportSnapshot={usageChartReportSnapshot}
        >
          <Flex
            backgroundColor={theme.panel_backgroundColor}
            borderRadius={theme.borderRadius_2}
            direction="column"
            height={500}
            padding={theme.space_md}
          >
            <Flex justifyContent="space-between" paddingBottom={theme.space_md}>
              <Text fontSize={theme.h3_fontSize}>
                {copyText.awsComputeOptimizationsChartTitleUsage}
              </Text>

              <Box flex="0 1 100%" />

              {/* USAGE DROPDOWN */}
              <Dropdown
                options={usageChartOptions.map((option) => ({
                  ...option,
                  onClick: () =>
                    setQueryParams({
                      selected_usage_chart_option: option.value,
                    }),
                }))}
                placement="bottom-end"
                selectedOption={
                  usageChartOptions.find(
                    (option) =>
                      option.value === queryParamState.selectedUsageChartOption
                  ) ?? usageChartOptions[0]
                }
              >
                <Button
                  iconEnd={<Icon icon={faChevronDown} />}
                  secondary
                  size="small"
                  width={140}
                >
                  {getOptionLabel(queryParamState.selectedUsageChartOption)}
                </Button>
              </Dropdown>
            </Flex>

            {/* USAGE CHART */}
            <Box flex="1 0 0">
              <AreaChart
                data={usageChartData}
                hideTotal
                dimensions={usageDimensions.map((dimension) => ({
                  name: dimension,
                  isDate: false,
                }))}
                disableDrilldown
                isLoading={isLoadingUsageChartData}
                measures={usageMeasures.map(getMeasuresWithUnit)}
                showLegend
                showTooltip
                stacked
                timeSeriesGranularity={queryParamState.dateRangeGranularity}
                xAxisKey="timestamp"
                yAxisLabel={getUsageYAxisLabelFromOption(
                  queryParamState.selectedUsageChartOption
                )}
              />
            </Box>
          </Flex>
        </InsightsSelector>
      </Grid>

      <Box
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_1}
        marginBottom={theme.space_md}
        marginTop={theme.space_md}
        padding={theme.space_md}
      >
        <Flex justifyContent="flex-end" alignItems="center">
          <Flex flex="1 0 0">
            {[
              awsOpenSearchDimensions.domainId,
              awsOpenSearchDimensions.region,
              awsOpenSearchDimensions.usageAccountId,
            ].map((instanceGroupFilterKey) => {
              let filterValue =
                queryParamState.instanceGroupFilters[instanceGroupFilterKey];

              if (!filterValue) return;

              if (filterValue === "") {
                filterValue = "null";
              }

              const copyTextKey = `awsOpenSearchInstanceGroupTableHeader_${instanceGroupFilterKey}`;

              return (
                <Chip
                  key={instanceGroupFilterKey}
                  label={copyText[copyTextKey]}
                  text={filterValue}
                  onClick={() => {
                    const nextFilters = {
                      ...queryParamState.instanceGroupFilters,
                    };

                    nextFilters[instanceGroupFilterKey] = null;

                    if (
                      Object.values(nextFilters).every(
                        (value) => value === null
                      )
                    ) {
                      setQueryParams({ instance_group_filters: null });
                    } else {
                      setQueryParams({ instance_group_filters: nextFilters });
                    }
                  }}
                />
              );
            })}
          </Flex>
          <Box width={300}>
            <TextInput
              iconEnd={
                <Icon
                  color={theme.text_color_secondary}
                  icon={searchText.length > 0 ? faClose : faSearch}
                  onClick={() =>
                    searchText.length > 0 ? setSearchText("") : noop
                  }
                />
              }
              placeholder={copyText.searchInputPlaceholder}
              size="large"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value.toLowerCase())}
            />
          </Box>
          <CSVLink
            data={filteredTableData.map((datum) => ({
              domainID: datum.domainId,
              usageAccountID: datum.usageAccountId,
              region: datum.region,
              instanceCost: datum.instanceCost,
              blockStorageCost: datum.blockStorageCost,
              dataTransferCost: datum.dataTransferCost,
              awsInbound: datum.dataTransferAwsInbound,
              awsOutbound: datum.dataTransferAwsOutbound,
              interRegionInbound: datum.dataTransferInterRegionInbound,
              interRegionOutbound: datum.dataTransferInterRegionOutbound,
            }))}
            filename={`AWS-OpenSearch-${formatDate(new Date(), "MM-dd-yyyy")}`}
          >
            <Button
              iconStart={<IconExport />}
              secondary
              size="medium"
              marginLeft={theme.space_md}
            >
              {copyText.exportButtonLabel}
            </Button>
          </CSVLink>
        </Flex>
      </Box>

      <Box>
        {isLoadingInstanceGroups ? (
          <EmptyPlaceholder loading={isLoadingInstanceGroups} />
        ) : (
          <AWSOpenSearchInstanceGroupTable
            instanceGroups={filteredTableData}
            isLoading={isLoadingInstanceGroups}
            onInteraction={handleInteraction}
          />
        )}
      </Box>

      {renderInstanceTableModal()}
    </Box>
  );
}

// COST OPTIONS
const costChartOptions = [
  AWSOpenSearchCostChartOption.USAGE_TYPE,
  AWSOpenSearchCostChartOption.DATA_TRANSFER_DIRECTION,
  AWSOpenSearchCostChartOption.USAGE_ACCOUNT_ID,
  AWSOpenSearchCostChartOption.REGION,
  AWSOpenSearchCostChartOption.DOMAIN_ID,
  AWSOpenSearchCostChartOption.INSTANCE_TYPE,
].map((costOption) => ({
  label: getOptionLabel(costOption),
  value: costOption,
}));

// USAGE OPTIONS
const usageChartOptions = [
  AWSOpenSearchUsageChartOption.DATA_TRANSFER,
  AWSOpenSearchUsageChartOption.DOMAIN_ID,
  AWSOpenSearchUsageChartOption.INSTANCE_TYPE,
].map((usageOption) => ({
  label: getOptionLabel(usageOption),
  value: usageOption,
}));

function getQueryParamState(queryParams: QueryParams): QueryParamState {
  const dateRange =
    queryParams.date_range_start && queryParams.date_range_end
      ? [queryParams.date_range_start, queryParams.date_range_end]
      : getDateRangeFromDurationType(queryParams.duration);

  const dateRangeDurationInHours =
    dateRange[0] && dateRange[1]
      ? differenceInHours(endOfDay(dateRange[1]), dateRange[0])
      : 0;

  const isSmallDateRange =
    dateRangeDurationInHours > 0 && dateRangeDurationInHours <= 48;

  const dateRangeGranularity = isSmallDateRange
    ? TimeGranularity.HOUR
    : TimeGranularity.DAY;

  return {
    dateRange,
    dateRangeGranularity,
    duration: queryParams.duration,
    instanceGroupFilters:
      queryParams.instance_group_filters ?? instanceGroupFiltersDefault,
    selectedCostChartOption: queryParams.selected_cost_chart_option,
    selectedUsageChartOption: queryParams.selected_usage_chart_option,
  };
}

function getCostMeasuresFromOptions(option: AWSOpenSearchCostChartOption) {
  switch (option) {
    case AWSOpenSearchCostChartOption.USAGE_TYPE:
      return [
        awsOpenSearchMeasures.dataTransfer,
        awsOpenSearchMeasures.serverlessManagedStorageCost,
        awsOpenSearchMeasures.blockStorageCost,
        awsOpenSearchMeasures.instanceCost,
      ];
    case AWSOpenSearchCostChartOption.DATA_TRANSFER_DIRECTION:
      return [
        awsOpenSearchMeasures.dataTransferAwsInboundCost,
        awsOpenSearchMeasures.dataTransferAwsOutboundCost,
        awsOpenSearchMeasures.dataTransferInterRegionInboundCost,
        awsOpenSearchMeasures.dataTransferInterRegionOutboundCost,
      ];
    case AWSOpenSearchCostChartOption.USAGE_ACCOUNT_ID:
      return [awsOpenSearchMeasures.cost];
    case AWSOpenSearchCostChartOption.REGION:
      return [awsOpenSearchMeasures.cost];
    case AWSOpenSearchCostChartOption.DOMAIN_ID:
      return [awsOpenSearchMeasures.cost];
    case AWSOpenSearchCostChartOption.INSTANCE_TYPE:
      return [awsOpenSearchMeasures.cost];
    default: {
      const _exhaustiveCheck: never = option;
      return _exhaustiveCheck;
    }
  }
}

function getCostDimensionsFromOptions(option: AWSOpenSearchCostChartOption) {
  switch (option) {
    case AWSOpenSearchCostChartOption.USAGE_TYPE:
      return [];
    case AWSOpenSearchCostChartOption.DATA_TRANSFER_DIRECTION:
      return [];
    case AWSOpenSearchCostChartOption.USAGE_ACCOUNT_ID:
      return [awsOpenSearchDimensions.usageAccountId];
    case AWSOpenSearchCostChartOption.REGION:
      return [awsOpenSearchDimensions.region];
    case AWSOpenSearchCostChartOption.DOMAIN_ID:
      return [awsOpenSearchDimensions.domainId];
    case AWSOpenSearchCostChartOption.INSTANCE_TYPE:
      return [awsOpenSearchDimensions.instanceType];
    default: {
      const _exhaustiveCheck: never = option;
      return _exhaustiveCheck;
    }
  }
}

function getUsageMeasuresFromOption(option: AWSOpenSearchUsageChartOption) {
  switch (option) {
    case AWSOpenSearchUsageChartOption.DATA_TRANSFER:
      return [
        awsOpenSearchMeasures.dataTransferAwsInbound,
        awsOpenSearchMeasures.dataTransferAwsOutbound,
        awsOpenSearchMeasures.dataTransferInterRegionInbound,
        awsOpenSearchMeasures.dataTransferInterRegionOutbound,
      ];
    case AWSOpenSearchUsageChartOption.DOMAIN_ID:
    case AWSOpenSearchUsageChartOption.INSTANCE_TYPE:
      return [awsOpenSearchMeasures.nodeUsage];

    default: {
      const _exhaustiveCheck: never = option;
      return _exhaustiveCheck;
    }
  }
}

function getUsageDimensionsFromOption(option: AWSOpenSearchUsageChartOption) {
  switch (option) {
    case AWSOpenSearchUsageChartOption.DATA_TRANSFER:
      return [];
    case AWSOpenSearchUsageChartOption.DOMAIN_ID:
      return [awsOpenSearchDimensions.domainId];
    case AWSOpenSearchUsageChartOption.INSTANCE_TYPE:
      return [awsOpenSearchDimensions.instanceType];

    default: {
      const _exhaustiveCheck: never = option;
      return _exhaustiveCheck;
    }
  }
}

function getUsageYAxisLabelFromOption(option: AWSOpenSearchUsageChartOption) {
  switch (option) {
    case AWSOpenSearchUsageChartOption.DATA_TRANSFER:
      return undefined;
    case AWSOpenSearchUsageChartOption.DOMAIN_ID:
    case AWSOpenSearchUsageChartOption.INSTANCE_TYPE:
      return copyText.unitNodeHours;

    default: {
      const _exhaustiveCheck: never = option;
      return _exhaustiveCheck;
    }
  }
}

function getOptionLabel(
  option: AWSOpenSearchCostChartOption | AWSOpenSearchUsageChartOption
) {
  return copyText[`awsOpenSearchChartOptionLabel_${option}`];
}

const measuresWithUnit: { name: string; unit: UnitType }[] = [
  {
    name: awsOpenSearchMeasures.dataTransferAwsInbound,
    unit: UnitType.BINARY_BYTES,
  },
  {
    name: awsOpenSearchMeasures.dataTransferAwsOutbound,
    unit: UnitType.BINARY_BYTES,
  },
  {
    name: awsOpenSearchMeasures.dataTransferInterRegionInbound,
    unit: UnitType.BINARY_BYTES,
  },
  {
    name: awsOpenSearchMeasures.dataTransferInterRegionOutbound,
    unit: UnitType.BINARY_BYTES,
  },
  { name: awsOpenSearchMeasures.nodeUsage, unit: UnitType.STANDARD },
];

function getMeasuresWithUnit(measure: string) {
  return (
    [...measuresWithUnit].find((other) => other.name === measure) ?? {
      name: measure,
      unit: UnitType.STANDARD,
    }
  );
}

function getCostChartQueryFilters(
  chartOption: AWSOpenSearchCostChartOption
): QueryFilter[] {
  switch (chartOption) {
    case AWSOpenSearchCostChartOption.DOMAIN_ID: {
      return [
        {
          name: awsOpenSearchDimensions.domainId,
          operator: Operator.SET,
          values: null,
        },
      ];
    }
    case AWSOpenSearchCostChartOption.INSTANCE_TYPE: {
      return [
        {
          name: awsOpenSearchDimensions.instanceType,
          operator: Operator.SET,
          values: null,
        },
      ];
    }

    default: {
      return [];
    }
  }
}

function getUsageChartQueryFilters(
  chartOption: AWSOpenSearchUsageChartOption
): QueryFilter[] {
  switch (chartOption) {
    case AWSOpenSearchUsageChartOption.DOMAIN_ID: {
      return [
        {
          name: awsOpenSearchDimensions.domainId,
          operator: Operator.SET,
          values: null,
        },
      ];
    }
    case AWSOpenSearchUsageChartOption.INSTANCE_TYPE: {
      return [
        {
          name: awsOpenSearchDimensions.instanceType,
          operator: Operator.SET,
          values: null,
        },
      ];
    }

    default: {
      return [];
    }
  }
}
