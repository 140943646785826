import { DateHelper } from "@/lib/dates";
import { useTheme } from "@emotion/react";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { RawData } from "@ternary/api-lib/analytics/types";
import { UnitType } from "@ternary/api-lib/constants/analytics";
import {
  AzureCommitmentLookbackPeriod,
  AzureRateRecommendationTerm,
  AzureRateType,
  TimeGranularity,
} from "@ternary/api-lib/constants/enums";
import { AzureRateRecommendationEntity } from "@ternary/api-lib/core/types";
import StackedBarChart from "@ternary/api-lib/ui-lib/charts/StackedBarChart";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { sub } from "date-fns";
import React, { useMemo } from "react";
import {
  DecodedValueMap,
  StringParam,
  createEnumParam,
  useQueryParams,
} from "use-query-params";
import useAuthenticatedUser from "../../../../hooks/useAuthenticatedUser";
import useGetDataIntegrationsByTenantID from "../../../admin/hooks/useGetDataIntegrationsByTenantID";
import { getPayerAccountIDs } from "../../aws/utils";
import copyText from "../../copyText";
import useGetAzureCommitmentUsage from "../hooks/useGetAzureCommitmentUsage";
import useGetAzureCommittedUseRecommendations from "../hooks/useGetAzureCommittedUseRecommendations";
import {
  AzureCommittedUseChartData,
  AzureCommittedUseFilter,
  AzureCommittedUseMeasures,
} from "../types";
import AzureCommittedUseChart from "./AzureCommittedUseChart";
import AzureCommittedUseFilterControls from "./AzureCommittedUseFilterControls";
import AzureCommittedUseMeter from "./AzureCommittedUseMeter";
import AzureReservedInstanceRecommendationTable from "./AzureReservedInstanceRecommendationTable";
import AzureSavingsPlanRecommendationTable from "./AzureSavingsPlanRecommendationTable";

const defaultType = AzureRateType.RI;

const queryParamConfigMap = {
  type: createEnumParam(Object.values(AzureRateType)),

  selected_rows: StringParam,
  payer_id: StringParam,
  rec_payer_id: StringParam,

  // FILTERS
  coverage_type: StringParam,
  lookback_p: StringParam,
  term: StringParam,
};

const defaultCommittedUseRecommendations = [];

type Interaction = AzureCommittedUseFilterControls.Interaction;

export default function AzureCommittedUseRecommendationsContainer() {
  const theme = useTheme();
  const authenticatedUser = useAuthenticatedUser();

  const [queryParams, setQueryParams] = useQueryParams(queryParamConfigMap);

  const filters = useMemo(
    () => getFiltersFromQueryParams(queryParams),
    [queryParams]
  );
  const committedUseType = queryParams.type ?? defaultType;

  const dateRange = useMemo(
    () => getDateRangeFromLookback(filters.lookbackPeriod),
    [filters.lookbackPeriod]
  );

  const {
    data: azureRateRecommendations = defaultCommittedUseRecommendations,
    isLoading: isLoadingCommittedUseRecommendations,
  } = useGetAzureCommittedUseRecommendations(authenticatedUser.tenant.id);

  const { data: integrations = [], isLoading: isLoadingClouds } =
    useGetDataIntegrationsByTenantID(authenticatedUser.tenant.id);

  const integrationNamesKeyedByIntegrationId = Object.fromEntries(
    integrations.map((integration) => [integration.id, integration.name])
  );

  const selectedIDs = getSelectedRecIDs(queryParams);

  // RESERVED INSTANCES
  const filteredRIs = useMemo(
    () => getFilteredRIs(azureRateRecommendations, filters),
    [filters, azureRateRecommendations]
  );
  const unselectedRIs = removeSelectedRecsByIDs(filteredRIs, selectedIDs);
  const selectedRIs = getSelectedRecsByIDs(filteredRIs, selectedIDs);

  // SAVINGS PLANS
  const filteredSPs = useMemo(
    () => getFilteredSPs(azureRateRecommendations, filters),
    [filters, azureRateRecommendations]
  );

  const unselectedSPs = removeSelectedRecsByIDs(filteredSPs, selectedIDs);
  const selectedSPs = getSelectedRecsByIDs(filteredSPs, selectedIDs);

  const payerAccountIDs = getPayerAccountIDs(azureRateRecommendations);
  const selectedPayerAccountID = queryParams.rec_payer_id ?? null;
  if (selectedPayerAccountID === null && payerAccountIDs.length > 0) {
    setQueryParams({ rec_payer_id: payerAccountIDs[0] }, "replaceIn");
  }

  const dailyNormalizedRICostInCart = selectedRIs.reduce(
    (sum, ri) =>
      sum +
      (ri.term === AzureRateRecommendationTerm.ONE_YEAR
        ? ri.estimatedOnDemandCost / 365
        : ri.term === AzureRateRecommendationTerm.THREE_YEARS
          ? ri.estimatedOnDemandCost / 1068
          : ri.term === AzureRateRecommendationTerm.FIVE_YEARS
            ? ri.estimatedOnDemandCost / (365 * 5)
            : 0),
    0
  );

  const dailySPCommitmentCostInCart = selectedSPs.reduce(
    (sum, sp) =>
      sum +
      (sp.term === AzureRateRecommendationTerm.ONE_YEAR
        ? sp.estimatedOnDemandCost / 365
        : sp.term === AzureRateRecommendationTerm.THREE_YEARS
          ? sp.estimatedOnDemandCost / 1068
          : sp.term === AzureRateRecommendationTerm.FIVE_YEARS
            ? sp.estimatedOnDemandCost / (365 * 5)
            : 0),
    0
  );

  const {
    data: commitmentInventoryChartData = [],
    isLoading: isLoadingCommitmentInventoryChartData,
  } = useGetAzureCommitmentUsage({ dateRange });

  const chartData = useMemo(
    () =>
      committedUseType === AzureRateType.RI
        ? getRIChartData(
            commitmentInventoryChartData,
            dailyNormalizedRICostInCart
          )
        : getSPChartData(
            commitmentInventoryChartData,
            dailySPCommitmentCostInCart
          ),
    [
      commitmentInventoryChartData,
      committedUseType,
      dailyNormalizedRICostInCart,
      dailySPCommitmentCostInCart,
    ]
  );

  function toggleRecSelection(rec: { id: string }) {
    const nextSelectedIDs = selectedIDs.includes(rec.id)
      ? selectedIDs.filter((id) => id !== rec.id)
      : [rec.id, ...selectedIDs];
    setQueryParams({ selected_rows: nextSelectedIDs.join(",") });
  }

  function handleInteraction(interaction: Interaction) {
    switch (interaction.type) {
      case AzureCommittedUseFilterControls.INTERACTION_CHANGE_LOOKBACK_PERIOD:
        setQueryParams({
          selected_rows: null,
          lookback_p:
            interaction.lookbackPeriod !== defaultFilters.lookbackPeriod
              ? interaction.lookbackPeriod
              : null,
        });
        break;
      case AzureCommittedUseFilterControls.INTERACTION_CHANGE_PAYER_ACCOUNT_ID:
        setQueryParams({
          payer_id: interaction.accountID,
          rec_payer_id: interaction.accountID,
          selected_rows: null,
        });
        break;
      case AzureCommittedUseFilterControls.INTERACTION_CHANGE_TERM:
        setQueryParams({
          selected_rows: null,
          term:
            interaction.term !== defaultFilters.term ? interaction.term : null,
        });
        break;
      case AzureCommittedUseFilterControls.INTERACTION_CHANGE_TYPE:
        setQueryParams({
          selected_rows: null,
          type:
            interaction.committedUseType !== defaultType
              ? interaction.committedUseType
              : null,
        });
        break;
      case AzureCommittedUseFilterControls.INTERACTION_COVERAGE_TYPE_CLICKED:
        setQueryParams({
          selected_rows: null,
          coverage_type:
            interaction.coverageType !== defaultFilters.coverageType
              ? interaction.coverageType
              : null,
        });
        break;
      default:
        break;
    }
  }

  const isLoading =
    isLoadingClouds ||
    isLoadingCommittedUseRecommendations ||
    isLoadingCommitmentInventoryChartData;

  return (
    <Box>
      <Box marginBottom={theme.space_lg}>
        <AzureCommittedUseFilterControls
          cloudNamesKeyedByCloudID={integrationNamesKeyedByIntegrationId}
          committedUseType={committedUseType}
          filters={filters}
          isLoading={isLoading}
          reservedInstanceRecommendations={azureRateRecommendations}
          savingsPlanRecommendations={azureRateRecommendations}
          onInteraction={handleInteraction}
        />
      </Box>

      <Box borderRadius={theme.borderRadius_1} marginBottom={theme.space_lg}>
        <AzureCommittedUseMeter
          committedUseType={committedUseType}
          isLoading={isLoading}
          reservedInstanceRecommendations={filteredRIs}
          savingsPlanRecommendations={filteredSPs}
        />
      </Box>
      <Flex height={500} marginBottom={theme.space_lg}>
        <Flex
          direction="column"
          padding={theme.space_sm}
          borderRadius={theme.borderRadius_1}
          backgroundColor={theme.panel_backgroundColor}
          paddingBottom={theme.space_md}
          width={`calc(50% - (${theme.space_lg} / 2))`}
        >
          <Text fontSize={theme.h3_fontSize} marginBottom={theme.space_sm}>
            {copyText.awsChartTitleUsage}
          </Text>
          <Box flex="1 0 0">
            {committedUseType === AzureRateType.RI ? (
              <AzureCommittedUseChart
                data={chartData}
                hasEstimated={dailyNormalizedRICostInCart > 0}
                isLoading={isLoading}
                committedUseType={AzureRateType.RI}
              />
            ) : (
              <AzureCommittedUseChart
                data={chartData}
                hasEstimated={dailySPCommitmentCostInCart > 0}
                isLoading={isLoading}
                committedUseType={AzureRateType.SP}
              />
            )}
          </Box>
        </Flex>

        <Box width={theme.space_lg} />

        <Flex
          direction="column"
          padding={theme.space_sm}
          borderRadius={theme.borderRadius_1}
          backgroundColor={theme.panel_backgroundColor}
          paddingBottom={theme.space_md}
          width={`calc(50% - (${theme.space_lg} / 2))`}
        >
          <Text fontSize={theme.h3_fontSize} marginBottom={theme.space_sm}>
            {copyText.awsChartTitleCost}
          </Text>
          <Box flex="1 0 0">
            <StackedBarChart
              clustered
              data={commitmentInventoryChartData}
              dimensions={[]}
              isLoading={isLoading}
              readableKeys={readableKeys}
              measures={[
                {
                  name:
                    committedUseType === AzureRateType.RI
                      ? AzureCommittedUseMeasures.riAmortizedCost
                      : AzureCommittedUseMeasures.spAmortizedCost,
                  unit: UnitType.CURRENCY,
                },
                {
                  name: AzureCommittedUseMeasures.onDemandUsageCost,
                  unit: UnitType.CURRENCY,
                },
              ].map((measure) => ({
                ...measure,
                name: measure.name,
              }))}
              showLegend
              showTooltip
              timeSeriesGranularity={TimeGranularity.DAY}
              xAxisKey="timestamp"
            />
          </Box>
        </Flex>
      </Flex>

      {/* selected */}
      {selectedRIs.length + selectedSPs.length > 0 && (
        <Box marginBottom={theme.space_lg}>
          <Box>
            <Text fontSize={theme.h3_fontSize} marginBottom={theme.space_sm}>
              {copyText.azureTableTitleRecommendationsRemove}
            </Text>
          </Box>

          <Box overflowX="auto">
            {committedUseType === AzureRateType.RI ? (
              <AzureReservedInstanceRecommendationTable
                buttonIcon={<Icon icon={faMinus} />}
                isLoading={isLoading}
                reservedInstanceRecommendations={selectedRIs}
                onSelectRecommendation={toggleRecSelection}
              />
            ) : (
              <AzureSavingsPlanRecommendationTable
                buttonIcon={<Icon icon={faMinus} />}
                isLoading={isLoading}
                savingPlanRecommendations={selectedSPs}
                onSelectRecommendation={toggleRecSelection}
              />
            )}
          </Box>
        </Box>
      )}

      {/* OPTIONS */}
      <Box marginBottom={theme.space_lg}>
        <Box>
          <Text fontSize={theme.h3_fontSize} marginBottom={theme.space_sm}>
            {copyText.azureTableTitleRecommendations}
          </Text>
        </Box>

        <Box overflowX="auto">
          {committedUseType === AzureRateType.RI ? (
            <AzureReservedInstanceRecommendationTable
              buttonIcon={<Icon icon={faPlus} />}
              isLoading={isLoading}
              reservedInstanceRecommendations={unselectedRIs}
              onSelectRecommendation={toggleRecSelection}
            />
          ) : (
            <AzureSavingsPlanRecommendationTable
              buttonIcon={<Icon icon={faPlus} />}
              isLoading={isLoading}
              savingPlanRecommendations={unselectedSPs}
              onSelectRecommendation={toggleRecSelection}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}

const readableKeys: Record<string, string> = {
  onDemandUsageCost: copyText.awsReadableKey_onDemandCost,
  riAmortizedCost: copyText.awsReadableKey_riDailyCost,
  riUnusedCost: copyText.awsReadableKey_riUnusedCommitmentCost,
  spAmortizedCost: copyText.awsReadableKey_spDailyCost,
  spUnusedCost: copyText.awsReadableKey_spDailyCost,
};

export const defaultFilters: AzureCommittedUseFilter = {
  coverageType: "All",
  lookbackPeriod: AzureCommitmentLookbackPeriod.SEVEN_DAYS,
  payerAccountID: "",
  term: AzureRateRecommendationTerm.ONE_YEAR,
};

function getFiltersFromQueryParams(
  params: DecodedValueMap<typeof queryParamConfigMap>
): AzureCommittedUseFilter {
  return {
    coverageType: params.coverage_type
      ? params.coverage_type
      : defaultFilters.coverageType,
    lookbackPeriod: params.lookback_p ?? defaultFilters.lookbackPeriod,
    payerAccountID: params.rec_payer_id
      ? params.rec_payer_id
      : defaultFilters.payerAccountID,
    term: params.term ?? defaultFilters.term,
  };
}

function getSelectedRecIDs(
  params: DecodedValueMap<typeof queryParamConfigMap>
) {
  return params.selected_rows ? params.selected_rows.split(",") : [];
}

function getFilteredRIs(
  ris: AzureRateRecommendationEntity[],
  filter: AzureCommittedUseFilter
) {
  return ris.filter((ri) => {
    if (ri.azureRateType !== AzureRateType.RI) {
      return false;
    }

    if (ri.cloudID !== filter.payerAccountID) {
      return false;
    }

    if (
      filter.coverageType !== "All" &&
      ri.coverageType !== filter.coverageType
    ) {
      return false;
    }

    if (filter.term.toUpperCase() !== ri.term.toUpperCase()) {
      return false;
    }

    if (filter.lookbackPeriod !== ri.lookbackPeriodDays) {
      return false;
    }

    return true;
  });
}

function getFilteredSPs(
  sps: AzureRateRecommendationEntity[],
  filter: AzureCommittedUseFilter
) {
  return sps.filter((sp) => {
    if (sp.azureRateType !== AzureRateType.SP) {
      return false;
    }
    if (sp.cloudID !== filter.payerAccountID) {
      return false;
    }

    if (
      filter.coverageType !== "All" &&
      sp.coverageType !== filter.coverageType
    ) {
      return false;
    }

    if (filter.term.toUpperCase() !== sp.term.toUpperCase()) {
      return false;
    }

    if (filter.lookbackPeriod !== sp.lookbackPeriodDays) {
      return false;
    }

    return true;
  });
}

function getDateRangeFromLookback(lookback: string) {
  const now = new DateHelper();

  switch (lookback) {
    case AzureCommitmentLookbackPeriod.SIXTY_DAYS:
      return [sub(now.date, { days: 60 }), now.date];
    case AzureCommitmentLookbackPeriod.THIRTY_DAYS:
      return [sub(now.date, { days: 30 }), now.date];
    case AzureCommitmentLookbackPeriod.FOURTEEN_DAYS:
      return [sub(now.date, { days: 14 }), now.date];
    case AzureCommitmentLookbackPeriod.SEVEN_DAYS:
      return [sub(now.date, { days: 7 }), now.date];
    default:
      return [sub(now.date, { days: 30 }), now.date];
  }
}

function getRIChartData(
  data: RawData[],
  estimatedAmount: number
): AzureCommittedUseChartData[] {
  return data.map((datum) => {
    const onDemand = Number(datum.onDemandUsageCost ?? 0);
    const riAmortizedCost = Number(datum.riAmortizedCost ?? 0);
    let timestamp = "";
    if (typeof datum.timestamp === "string") {
      timestamp = datum.timestamp;
    }
    return {
      covered: riAmortizedCost,
      estimated: estimatedAmount,
      usage: onDemand + riAmortizedCost,
      timestamp,
    };
  });
}

function getSPChartData(
  data: RawData[],
  estimatedAmount: number
): AzureCommittedUseChartData[] {
  return data.map((datum) => {
    const onDemand = Number(datum.onDemandUsageCost ?? 0);
    const spAmortizedCost = Number(datum.spAmortizedCost ?? 0);
    let timestamp = "";
    if (typeof datum.timestamp === "string") {
      timestamp = datum.timestamp;
    }
    return {
      covered: spAmortizedCost,
      estimated: estimatedAmount,
      usage: onDemand + spAmortizedCost,
      timestamp,
    };
  });
}

function getSelectedRecsByIDs<Rec extends { id: string }>(
  recs: Rec[],
  ids: string[]
): Rec[] {
  const idsSet = Object.fromEntries(ids.map((id) => [id, true]));
  return recs.filter((rec) => !!idsSet[rec.id]);
}

function removeSelectedRecsByIDs<Rec extends { id: string }>(
  recs: Rec[],
  ids: string[]
): Rec[] {
  const idsSet = Object.fromEntries(ids.map((id) => [id, true]));
  return recs.filter((rec) => !idsSet[rec.id]);
}
