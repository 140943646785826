import { DateHelper } from "@/lib/dates";
import { createStructParam } from "@/lib/use-query-params";
import DateRangeControls from "@/ui-lib/components/DateRangeControls";
import Dropdown from "@/ui-lib/components/Dropdown";
import Grid from "@/ui-lib/components/Grid";
import Modal from "@/ui-lib/components/Modal";
import IconExport from "@/ui-lib/icons/IconExport";
import { DateRange } from "@/utils/dates";
import { useDebounce } from "@/utils/timers";
import { useTheme } from "@emotion/react";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import { getCubeDateRangeFromDurationType } from "@ternary/api-lib/analytics/utils";
import { UnitType } from "@ternary/api-lib/constants/analytics";
import {
  ChartType,
  CloudProviderType,
  DataSource,
  DurationType,
  Operator,
  RecommendationCategory,
  TimeGranularity,
} from "@ternary/api-lib/constants/enums";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import AreaChart from "@ternary/web-ui-lib/charts/AreaChart";
import StackedBarChart from "@ternary/web-ui-lib/charts/StackedBarChart";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { differenceInHours, endOfDay, format } from "date-fns";
import React, { useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import {
  DateParam,
  DecodedValueMap,
  JsonParam,
  createEnumParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import { z } from "zod";
import useGetRawData from "../../../../api/analytics/useGetRawData";
import useGetRecommendationsByTenantID from "../../../../api/core/hooks/useGetRecommendationsByTenantID";
import InsightsSelector from "../../../../components/InsightsSelector";
import useAuthenticatedUser from "../../../../hooks/useAuthenticatedUser";
import useAvailableGlobalDate from "../../../../hooks/useAvailableGlobalDate";
import useGatekeeper from "../../../../hooks/useGatekeeper";
import copyText from "../../copyText";
import useGetComputeSpendSummary from "../../hooks/useGetComputeSpendSummary";
import useGetGCPComputeInstanceGroups from "../hooks/useGetGCPComputeInstanceGroups";
import useGetGCPComputeInstances from "../hooks/useGetGCPComputeInstances";
import {
  GCPComputeInstance,
  GCPComputeInstanceGroup,
  GCPComputeInstanceGroupFilters,
} from "../types";
import GCPComputeInstanceGroupTable from "./GCPComputeInstanceGroupTable";
import GCPComputeInstanceGroupTableControls from "./GCPComputeInstanceGroupTableControls";
import GCPComputeInstanceMeters from "./GCPComputeInstanceMeters";
import GCPComputeInstanceTable from "./GCPComputeInstanceTable";

const GCPComputeCostChartOption = {
  CATEGORY: "CATEGORY",
  FAMILY: "FAMILY",
  OPERATING_SYSTEM: "OPERATING_SYSTEM",
  PROJECT_ID: "PROJECT_ID",
  REGION: "REGION",
  STORAGE_CLASS: "STORAGE_CLASS",
  STORAGE_TYPE: "STORAGE_TYPE",
} as const;

type GCPComputeCostChartOption =
  (typeof GCPComputeCostChartOption)[keyof typeof GCPComputeCostChartOption];

const GCPComputeUsageChartOption = {
  DISK: "DISK",
  MEMORY: "MEMORY",
  NETWORK: "NETWORK",
  VCPU: "VCPU",
} as const;

type GCPComputeUsageChartOption =
  (typeof GCPComputeUsageChartOption)[keyof typeof GCPComputeUsageChartOption];

type Interaction =
  | GCPComputeInstanceGroupTable.Interaction
  | GCPComputeInstanceGroupTableControls.Interaction;

type QueryParams = DecodedValueMap<typeof queryParamConfigMap>;

type QueryParamState = {
  dateRange: DateRange;
  dateRangeGranularity: TimeGranularity;
  duration: DurationType;
  instanceGroupFilters: z.infer<typeof instanceGroupFiltersStruct>;
  selectedCostChartOption: GCPComputeCostChartOption;
  selectedInstanceGroup: z.infer<typeof selectedInstanceGroupStruct> | null;
  selectedUsageChartOption: GCPComputeUsageChartOption;
};

const instanceGroupFiltersDefault = {
  family: null,
  familyType: null,
  projectId: null,
  region: null,
};

const instanceGroupFiltersStruct = z.object({
  family: z.nullable(z.string()),
  familyType: z.nullable(z.string()),
  projectId: z.nullable(z.string()),
  region: z.nullable(z.string()),
});

const selectedInstanceGroupStruct = z.object({
  family: z.string(),
  familyType: z.string(),
  projectId: z.string(),
  region: z.string(),
});

const costChartOptionEnum = createEnumParam(
  Object.values(GCPComputeCostChartOption)
);
const durationEnum = createEnumParam(Object.values(DurationType));
const usageChartOptionEnum = createEnumParam(
  Object.values(GCPComputeUsageChartOption)
);

const queryParamConfigMap = {
  date_range_end: DateParam,
  date_range_start: DateParam,
  duration: withDefault(durationEnum, DurationType.LAST_THIRTY_DAYS),
  instance_table_sort: JsonParam,
  instance_group_filters: createStructParam(instanceGroupFiltersStruct),
  selected_cost_chart_option: withDefault(
    costChartOptionEnum,
    GCPComputeCostChartOption.CATEGORY
  ),
  selected_group: createStructParam(selectedInstanceGroupStruct),
  selected_usage_chart_option: withDefault(
    usageChartOptionEnum,
    GCPComputeUsageChartOption.VCPU
  ),
};

export default function GCPComputeVisibiltyContainer() {
  const authenticatedUser = useAuthenticatedUser();
  const gatekeeper = useGatekeeper();
  const globalDate = useAvailableGlobalDate();
  const theme = useTheme();

  //
  // STATE
  //

  const [queryParams, setQueryParams] = useQueryParams(queryParamConfigMap);

  const queryParamState = getQueryParamState(queryParams);

  const [searchText, setSearchText] = useState("");

  const debouncedSearchText = useDebounce(searchText);

  //
  // QUERIES
  //

  const dateRange = globalDate.date ?? queryParamState.dateRange;

  const costDimensions = getCostDimensionsFromOption(
    queryParamState.selectedCostChartOption
  );

  const costMeasures = ["cost"];

  const usageMeasures = getUsageMeasuresFromOption(
    queryParamState.selectedUsageChartOption,
    queryParamState.dateRangeGranularity
  );

  const { data: costChartData = [], isFetching: isLoadingCostChartData } =
    useGetRawData({
      dataSource: DataSource.GCP_COMPUTE_VISIBILITY,
      dateRange,
      dimensions: costDimensions,
      queryFilters: [
        {
          name: costDimensions[0],
          operator: Operator.NOT_EQUALS,
          values: ["null"],
        },
      ],

      granularity: queryParamState.dateRangeGranularity,
      measures: costMeasures,
    });

  const { data: recommendations = [], isLoading: isLoadingRecommendations } =
    useGetRecommendationsByTenantID(authenticatedUser.tenant.fsDocID, {
      category: RecommendationCategory.COMPUTE,
      cloudProviderType: CloudProviderType.GCP,
      enabled: gatekeeper.canListRecommendations,
    });

  const gcpComputeSpendSummaries = useGetComputeSpendSummary({
    dataSource: DataSource.GCP_COMPUTE_VISIBILITY,
  });

  const [{ data: currentMTD }, { data: lastMonthFull }, { data: lastMTD }] =
    gcpComputeSpendSummaries;

  const isLoadingSpendSummaries = gcpComputeSpendSummaries.some(
    (summary) => summary.isFetching
  );

  const { data: usageChartData = [], isFetching: isLoadingUsageChartData } =
    useGetRawData({
      dataSource: DataSource.GCP_COMPUTE_VISIBILITY,
      dateRange,

      granularity: queryParamState.dateRangeGranularity,
      measures: usageMeasures,
    });

  const { data: instanceGroups, isFetching: isLoadingInstanceGroups } =
    useGetGCPComputeInstanceGroups({
      dateRange,
    });

  const { data: instances = [], isFetching: isLoadingInstances } =
    useGetGCPComputeInstances(
      {
        dateRange,
        queryFilters: queryParamState.selectedInstanceGroup
          ? getInstanceGroupQueryFilters(queryParamState.selectedInstanceGroup)
          : [],
      },
      { enabled: Boolean(queryParamState.selectedInstanceGroup) }
    );

  //
  // MODIFIED QUERY DATA
  //

  const filteredInstanceGroups = useMemo(() => {
    return getFilteredInstanceGroups({
      allInstanceGroups: instanceGroups ?? [],
      instanceGroupFilters: queryParamState.instanceGroupFilters,
      searchText: debouncedSearchText,
    });
  }, [
    debouncedSearchText,
    queryParamState.instanceGroupFilters,
    instanceGroups,
  ]);

  const csvDataInstanceGroups = useMemo(
    () => getCSVDataInstanceGroups(filteredInstanceGroups),
    [filteredInstanceGroups]
  );

  const csvDataSelectedInstances = useMemo(
    () => getCSVDataSelectedInstances(instances),
    [instances]
  );

  //
  // INTERACTIONS
  //

  function handleInteraction(interaction: Interaction) {
    switch (interaction.type) {
      case GCPComputeInstanceGroupTable.INTERACTION_FILTER_CLICKED: {
        const nextFilters = { ...queryParamState.instanceGroupFilters };

        nextFilters[interaction.filterKey] = interaction.filterValue;

        setQueryParams({ instance_group_filters: nextFilters });
        break;
      }
      case GCPComputeInstanceGroupTableControls.INTERACTION_REMOVE_FILTER_CLICKED: {
        const nextFilters = { ...queryParamState.instanceGroupFilters };

        nextFilters[interaction.filterKey] = null;

        if (Object.values(nextFilters).every((value) => value === null)) {
          setQueryParams({ instance_group_filters: null });
        } else {
          setQueryParams({ instance_group_filters: nextFilters });
        }
        break;
      }
      case GCPComputeInstanceGroupTableControls.INTERACTION_SEARCH_TEXT_UPDATED: {
        setSearchText(interaction.searchText);
        break;
      }
    }
  }

  const usageChartOptions = getUsageChartOptions();

  const costChartReportSnapshot = {
    chartType: ChartType.STACKED_BAR,
    dateRange:
      queryParamState.duration === DurationType.CUSTOM
        ? queryParamState.dateRange
        : null,
    dataSource: DataSource.GCP_COMPUTE_VISIBILITY,
    dimensions: costDimensions,
    durationType: queryParamState.duration,
    isFiscalMode: false,
    fiscalPeriodMap: null,
    granularity: queryParamState.dateRangeGranularity,
    measures: costMeasures,
    queryFilters: [
      {
        name: costDimensions[0],
        operator: Operator.NOT_EQUALS,
        values: ["null"],
      },
    ],
    name: copyText.gcpComputeCostReportSnapshotName,
    xAxisKey: "timestamp",
  };

  const usageChartReportSnapshot = {
    chartType: ChartType.AREA,
    dateRange:
      queryParamState.duration === DurationType.CUSTOM
        ? queryParamState.dateRange
        : null,
    dataSource: DataSource.GCP_COMPUTE_VISIBILITY,
    dimensions: [],
    durationType: queryParamState.duration,
    isFiscalMode: false,
    fiscalPeriodMap: null,
    granularity: queryParamState.dateRangeGranularity,
    measures: usageMeasures,
    name: copyText.gcpComputeUsageReportSnapshotName,
    xAxisKey: "timestamp",
  };

  return (
    <Box width="100%" paddingTop={theme.space_md}>
      <Box
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_2}
        marginBottom={theme.space_lg}
        padding={theme.space_md}
      >
        <GCPComputeInstanceMeters
          isLoading={isLoadingSpendSummaries || isLoadingRecommendations}
          lastMonthSpend={lastMonthFull?.totalCost ?? 0}
          lastMTDSpend={lastMTD?.totalCost ?? 0}
          thisMTDSpend={currentMTD?.totalCost ?? 0}
          recommendations={recommendations}
        />
      </Box>
      <Flex
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_1}
        justifyContent="flex-end"
        marginBottom={theme.space_lg}
        padding={theme.space_md}
      >
        <DateRangeControls
          dateRange={dateRange}
          durationType={queryParamState.duration}
          hiddenOptions={[
            DurationType.LAST_NINETY_DAYS,
            DurationType.QUARTER_TO_DATE,
            DurationType.YEAR_TO_DATE,
          ]}
          maxDate={new DateHelper().date}
          onChangeDateRange={(duration, newDateRange) => {
            setQueryParams({
              duration,
              ...(newDateRange && newDateRange[0] && newDateRange[1]
                ? {
                    date_range_start: newDateRange[0],
                    date_range_end: newDateRange[1],
                  }
                : {
                    date_range_start: null,
                    date_range_end: null,
                  }),
            });
          }}
        />
      </Flex>
      <Grid
        gridColumnGap={theme.space_lg}
        gridTemplateColumns={`repeat(2, calc(50% - (${theme.space_lg} / 2) ))`}
      >
        <InsightsSelector
          resourceName={copyText.gcpComputeCostReportSnapshotName}
          reportSnapshot={costChartReportSnapshot}
        >
          <Flex
            backgroundColor={theme.panel_backgroundColor}
            borderRadius={theme.borderRadius_2}
            direction="column"
            height={500}
            padding={theme.space_md}
          >
            <Flex justifyContent="space-between" paddingBottom={theme.space_md}>
              <Text fontSize={theme.h3_fontSize}>
                {copyText.gcpComputeOptimizationsChartTitleCost}
              </Text>

              {/* COST DROPDOWN */}
              <Dropdown
                options={costChartOptions.map((option) => ({
                  ...option,
                  onClick: () =>
                    setQueryParams({
                      selected_cost_chart_option: option.value,
                    }),
                }))}
                placement="bottom-end"
                selectedOption={
                  costChartOptions.find(
                    (option) =>
                      option.value === queryParamState.selectedCostChartOption
                  ) ?? costChartOptions[0]
                }
              >
                <Button
                  iconEnd={<Icon icon={faChevronDown} />}
                  secondary
                  size="small"
                  width={140}
                >
                  {getOptionLabel(queryParamState.selectedCostChartOption)}
                </Button>
              </Dropdown>
            </Flex>
            {/* COST CHART */}
            <Box flex="1 0 0">
              <StackedBarChart
                data={costChartData}
                dimensions={costDimensions.map((dimension) => ({
                  name: dimension,
                  isDate: false,
                }))}
                disableDrilldown
                isLoading={isLoadingCostChartData}
                maxGroupings={7}
                measures={costMeasures.map(getMeasureWithUnit)}
                showLegend
                showTooltip
                timeSeriesGranularity={queryParamState.dateRangeGranularity}
                xAxisKey="timestamp"
              />
            </Box>
          </Flex>
        </InsightsSelector>
        <InsightsSelector
          resourceName={copyText.gcpComputeUsageReportSnapshotName}
          reportSnapshot={usageChartReportSnapshot}
        >
          <Flex
            backgroundColor={theme.panel_backgroundColor}
            borderRadius={theme.borderRadius_2}
            direction="column"
            height={500}
            padding={theme.space_md}
          >
            <Flex justifyContent="space-between" paddingBottom={theme.space_md}>
              <Text fontSize={theme.h3_fontSize}>
                {copyText.gcpComputeOptimizationsChartTitleUsage}
              </Text>

              {/* USAGE DROPDOWN */}
              <Dropdown
                options={usageChartOptions.map((option) => ({
                  ...option,
                  onClick: () =>
                    setQueryParams({
                      selected_usage_chart_option: option.value,
                    }),
                }))}
                placement="bottom-end"
                selectedOption={
                  usageChartOptions.find(
                    (option) =>
                      option.value === queryParamState.selectedUsageChartOption
                  ) ?? usageChartOptions[0]
                }
              >
                <Button
                  iconEnd={<Icon icon={faChevronDown} />}
                  secondary
                  size="small"
                  width={140}
                >
                  {getOptionLabel(queryParamState.selectedUsageChartOption)}
                </Button>
              </Dropdown>
            </Flex>

            {/* USAGE CHART */}
            <Box flex="1 0 0">
              <AreaChart
                data={usageChartData}
                disableDrilldown
                hideTotal
                isLoading={isLoadingUsageChartData}
                measures={usageMeasures.map(getMeasureWithUnit)}
                readableKeys={readableMeasures}
                showLegend
                showTooltip
                stacked
                timeSeriesGranularity={queryParamState.dateRangeGranularity}
                xAxisKey="timestamp"
              />
            </Box>
          </Flex>
        </InsightsSelector>
      </Grid>

      <Box
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_1}
        marginVertical={theme.space_lg}
        padding={theme.space_md}
      >
        <GCPComputeInstanceGroupTableControls
          csvData={csvDataInstanceGroups}
          debouncedSearchText={debouncedSearchText}
          instanceGroupFilters={queryParamState.instanceGroupFilters}
          searchText={searchText}
          onInteraction={handleInteraction}
        />
      </Box>

      <Box width="100%" overflowX="auto">
        <GCPComputeInstanceGroupTable
          instanceGroups={filteredInstanceGroups}
          isLoadingInstanceGroups={isLoadingInstanceGroups}
          onInteraction={handleInteraction}
        />
      </Box>

      {queryParamState.selectedInstanceGroup !== null && (
        <Modal
          isOpen
          showCloseButton
          onClose={() =>
            setQueryParams({
              instance_table_sort: null,
              selected_group: null,
            })
          }
          minWidth={1100}
        >
          <Modal.Header>
            <Flex
              justifyContent="space-between"
              marginLeft={theme.space_sm}
              width="100%"
            >
              <Text fontSize={theme.h4_fontSize}>{"Instances"}</Text>
              <CSVLink
                data={csvDataSelectedInstances.rows}
                headers={csvDataSelectedInstances.headers}
                filename={`GCP-compute-instance-groups-${format(
                  new Date(),
                  "MM-dd-yyyy"
                )}`}
              >
                <Button
                  iconStart={<IconExport />}
                  marginRight={theme.space_md}
                  secondary
                  size="small"
                >
                  {copyText.exportButtonLabel}
                </Button>
              </CSVLink>
            </Flex>
          </Modal.Header>
          <Modal.Body>
            <GCPComputeInstanceTable
              instances={instances}
              isLoadingInstances={isLoadingInstances}
            />
          </Modal.Body>
        </Modal>
      )}
    </Box>
  );
}

// COST OPTIONS
const costChartOptions = [
  GCPComputeCostChartOption.CATEGORY,
  GCPComputeCostChartOption.FAMILY,
  GCPComputeCostChartOption.OPERATING_SYSTEM,
  GCPComputeCostChartOption.PROJECT_ID,
  GCPComputeCostChartOption.REGION,
  GCPComputeCostChartOption.STORAGE_CLASS,
  GCPComputeCostChartOption.STORAGE_TYPE,
].map((costOption) => ({
  label: getOptionLabel(costOption),
  value: costOption,
}));

// USAGE OPTIONS
function getUsageChartOptions() {
  const gatekeeper = useGatekeeper();

  return [
    GCPComputeUsageChartOption.DISK,
    GCPComputeUsageChartOption.MEMORY,
    ...(gatekeeper.isConfiguredResourceLevelBilling
      ? [GCPComputeUsageChartOption.NETWORK]
      : []),
    GCPComputeUsageChartOption.VCPU,
  ].map((usageOption) => ({
    label: getOptionLabel(usageOption),
    value: usageOption,
  }));
}

function getCostDimensionsFromOption(option: GCPComputeCostChartOption) {
  switch (option) {
    case GCPComputeCostChartOption.CATEGORY:
      return ["category"];
    case GCPComputeCostChartOption.FAMILY:
      return ["family"];
    case GCPComputeCostChartOption.OPERATING_SYSTEM:
      return ["operatingSystem"];
    case GCPComputeCostChartOption.PROJECT_ID:
      return ["projectId"];
    case GCPComputeCostChartOption.REGION:
      return ["region"];
    case GCPComputeCostChartOption.STORAGE_CLASS:
      return ["storageClass"];
    case GCPComputeCostChartOption.STORAGE_TYPE:
      return ["storageType"];

    default: {
      const _exhaustiveCheck: never = option;
      return _exhaustiveCheck;
    }
  }
}

const measuresWithUnit: { name: string; unit: UnitType }[] = [
  { name: "cost", unit: UnitType.CURRENCY },
  { name: "diskReadBytes", unit: UnitType.BYTES },
  { name: "diskWriteBytes", unit: UnitType.BYTES },
  { name: "productVCPU", unit: UnitType.STANDARD },
  { name: "productMemoryBytes", unit: UnitType.BYTES },
  { name: "productMemoryBytesChart", unit: UnitType.BYTES },
  { name: "provisionedDiskBytes", unit: UnitType.BYTES },
  { name: "receivedBytes", unit: UnitType.BYTES },
  { name: "sentBytes", unit: UnitType.BYTES },
];

function getMeasureWithUnit(measure: string) {
  return (
    measuresWithUnit.find((other) => other.name === measure) ?? {
      name: measure,
      unit: UnitType.STANDARD,
    }
  );
}

const csvAccessorsInstanceGroups = [
  "family",
  "projectId",
  "region",
  "familyType",
  "instanceCount",
  "totalCost",
  "productVCPU",
  "productMemoryBytes",
  "provisionedDiskBytes",
] as const;

const csvAccessorsSelectedInstances = [
  "instanceName",
  "instanceId",
  "totalCost",
  "instanceType",
  "productVCPU",
  "cpuUtilization",
  "productMemoryBytes",
  "ramUtilization",
  "provisionedDiskBytes",
  "diskUtilization",
] as const;

type CSVData = {
  headers: { key: string; label: string }[];
  rows: Record<string, string | number>[];
};

function getCSVDataInstanceGroups(groups: GCPComputeInstanceGroup[]): CSVData {
  if (!groups.length) {
    return { headers: [], rows: [] };
  }

  const rows = groups.map((group) => ({
    family: group.family,
    familyType: group.familyType,
    instanceCount: group.instanceIDCount,
    productMemoryBytes: group.productMemoryBytes,
    productVCPU: group.productVCPU,
    projectId: group.projectId,
    provisionedDiskBytes: group.provisionedDiskBytes,
    region: group.region,
    totalCost: group.totalCost,
  }));

  const headers = csvAccessorsInstanceGroups.map((csvAccessor) => {
    // ensure rows has a value for each accessor
    const key: keyof (typeof rows)[number] = csvAccessor;

    // ensure copyText has a value for each accessor
    const copyTextKey: keyof typeof copyText = `gcpComputeTableInstanceGroupHeader_${csvAccessor}`;
    const label = copyText[copyTextKey];

    return { key, label };
  });

  return { headers, rows };
}

function getCSVDataSelectedInstances(instances: GCPComputeInstance[]): CSVData {
  if (!instances.length) {
    return { headers: [], rows: [] };
  }

  const rows = instances.map((instance) => ({
    cpuUtilization: instance.cpuUtilization,
    diskUtilization: instance.diskUtilization,
    instanceId: instance.instanceId,
    instanceName: instance.instanceName,
    instanceType: instance.instanceType,
    productMemoryBytes: instance.productMemoryBytes,
    productVCPU: instance.productVCPU,
    provisionedDiskBytes: instance.provisionedDiskBytes,
    ramUtilization: instance.ramUtilization,
    totalCost: instance.totalCost,
  }));

  const headers = csvAccessorsSelectedInstances.map((csvAccessor) => {
    // ensure rows has a value for each accessor
    const key: keyof (typeof rows)[number] = csvAccessor;

    // ensure copyText has a value for each accessor
    const copyTextKey: keyof typeof copyText = `gcpComputeTableInstancesHeader_${csvAccessor}`;
    const label = copyText[copyTextKey];

    return { key, label };
  });

  return { headers, rows };
}

type GetFilteredInstanceGroupsParams = {
  allInstanceGroups: GCPComputeInstanceGroup[];
  instanceGroupFilters: GCPComputeInstanceGroupFilters;
  searchText: string | null;
};

function getFilteredInstanceGroups(
  params: GetFilteredInstanceGroupsParams
): GCPComputeInstanceGroup[] {
  const searchText = (params.searchText ?? "").toLowerCase().trim();

  return params.allInstanceGroups.filter((instanceGroup) => {
    if (
      !isInstanceGroupPassingFilters(instanceGroup, params.instanceGroupFilters)
    ) {
      return false;
    }

    if (!isSearchTextInInstanceGroup(instanceGroup, searchText)) {
      return false;
    }

    return true;
  });
}

function getInstanceGroupQueryFilters(
  selectedInstanceGroup: z.infer<typeof selectedInstanceGroupStruct>
): QueryFilter[] {
  const groupDimnesionKeys = [
    "family",
    "familyType",
    "projectId",
    "region",
  ] as const;

  const andFilter = groupDimnesionKeys.map(
    (key): QueryFilter =>
      selectedInstanceGroup[key] === ""
        ? {
            name: key,
            operator: Operator.NOT_SET,
          }
        : {
            name: key,
            operator: Operator.EQUALS,
            values: [selectedInstanceGroup[key]],
          }
  );

  return [{ and: andFilter }];
}

function getOptionLabel(
  option: GCPComputeCostChartOption | GCPComputeUsageChartOption
) {
  return copyText[`gcpComputeChartOptionLabel_${option}`] ?? option;
}

function getQueryParamState(queryParams: QueryParams): QueryParamState {
  const dateRange =
    queryParams.date_range_start && queryParams.date_range_end
      ? [queryParams.date_range_start, queryParams.date_range_end]
      : getCubeDateRangeFromDurationType(queryParams.duration);

  const dateRangeDurationInHours =
    dateRange[0] && dateRange[1]
      ? differenceInHours(endOfDay(dateRange[1]), dateRange[0])
      : 0;

  const isSmallDateRange =
    dateRangeDurationInHours > 0 && dateRangeDurationInHours <= 48;

  const dateRangeGranularity = isSmallDateRange
    ? TimeGranularity.HOUR
    : TimeGranularity.DAY;

  return {
    dateRange,
    dateRangeGranularity,
    duration: queryParams.duration,
    instanceGroupFilters:
      queryParams.instance_group_filters ?? instanceGroupFiltersDefault,
    selectedCostChartOption: queryParams.selected_cost_chart_option,
    selectedInstanceGroup: queryParams.selected_group ?? null,
    selectedUsageChartOption: queryParams.selected_usage_chart_option,
  };
}

const readableMeasures = {
  productVCPUChart: "productVCPU",
  productMemoryBytesChart: "productMemoryBytes",
};

function getUsageMeasuresFromOption(
  option: GCPComputeUsageChartOption,
  granularity: TimeGranularity
) {
  const gateKeeper = useGatekeeper();

  switch (option) {
    case GCPComputeUsageChartOption.DISK:
      return gateKeeper.isConfiguredResourceLevelBilling
        ? ["diskReadBytes", "diskWriteBytes"]
        : ["provisionedDiskBytes"];
    case GCPComputeUsageChartOption.MEMORY:
      return granularity === TimeGranularity.HOUR
        ? ["productMemoryBytes"]
        : ["productMemoryBytesChart"];
    case GCPComputeUsageChartOption.NETWORK:
      return ["receivedBytes", "sentBytes"];
    case GCPComputeUsageChartOption.VCPU:
      return granularity === TimeGranularity.HOUR
        ? ["productVCPU"]
        : ["productVCPUChart"];

    default: {
      const _exhaustiveCheck: never = option;
      return _exhaustiveCheck;
    }
  }
}

function isInstanceGroupPassingFilters(
  instanceGroup: GCPComputeInstanceGroup,
  filters: GCPComputeInstanceGroupFilters
): boolean {
  const groupDimnesionKeys = ["family", "familyType", "projectId", "region"];

  return !groupDimnesionKeys.some(
    (key) =>
      filters[key] !== null &&
      instanceGroup[key].toLowerCase().trim() !==
        filters[key].toLowerCase().trim()
  );
}

function isSearchTextInInstanceGroup(
  instanceGroup: GCPComputeInstanceGroup,
  searchText: string
): boolean {
  if (searchText === "") return true;

  const values = [
    instanceGroup.family,
    instanceGroup.familyType,
    instanceGroup.projectId,
    instanceGroup.region,
  ].map((value) => (value === "" ? "null" : value));

  return values.some((value) =>
    value.toLowerCase().trim().includes(searchText)
  );
}
