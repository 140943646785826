import DateRangeControls from "@/ui-lib/components/DateRangeControls";
import IconExport from "@/ui-lib/icons/IconExport";
import getMergeState from "@/utils/getMergeState";
import { useTheme } from "@emotion/react";
import { faChevronDown, faSearch } from "@fortawesome/free-solid-svg-icons";
import { getCubeDateRangeFromDurationType } from "@ternary/api-lib/analytics/utils";
import { UnitType } from "@ternary/api-lib/constants/analytics";
import {
  DataSource,
  DurationType,
  Operator,
  TimeGranularity,
} from "@ternary/api-lib/constants/enums";
import StackedBarChart from "@ternary/api-lib/ui-lib/charts/StackedBarChart";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import { formatDate } from "@ternary/api-lib/ui-lib/utils/dates";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import prettyBytes from "pretty-bytes";
import React, { useState } from "react";
import { CSVLink } from "react-csv";
import {
  JsonParam,
  StringParam,
  createEnumParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import useGetRawData from "../../../../api/analytics/useGetRawData";
import useGetSpendSummaries from "../../../../api/analytics/useGetSpendSummaries";
import useAvailableGlobalDate from "../../../../hooks/useAvailableGlobalDate";
import Dropdown from "../../../../ui-lib/components/Dropdown";
import TextInput from "../../../../ui-lib/components/TextInput";
import copyText from "../../copyText";
import useGetSnowflakeDatabaseUsage from "../hooks/useGetSnowflakeDatabaseUsage";
import useGetSnowflakeDatabaseUsageBytes from "../hooks/useGetSnowflakeDatabaseUsageBytes";
import SnowflakeDatabaseMeters from "./SnowflakeDatabaseMeters";
import { SnowflakeDatabaseUsageTable } from "./SnowflakeDatabaseUsageTable";

export const AccountFilterTypes = {
  BYTES: "BYTES",
  COUNT: "COUNT",
} as const;

type DatabaseUsage = {
  accountName: string;
  averagePerDatabaseDailyDatabaseBytes: number;
  averagePerDatabaseDailyFailsafeBytes: number;
  comment: string;
  databaseID: number;
  databaseName: string;
  databaseOwner: string;
  deletionTime: string;
  lastAlteredTime: string;
  retentionDays: number;
};

type CSVDatabaseUsage = {
  accountName: string;
  avgBytes: string;
  avgFailsafeBytes: string;
  comment: string;
  databaseID: number;
  databaseName: string;
  databaseOwner: string;
  deletionTime: string;
  lastAlteredTime: string;
  retentionDays: number;
};

interface State {
  searchText: string;
  selectedWarehouseID: string;
  showSubTable: boolean;
}

const initialState: State = {
  searchText: "",
  selectedWarehouseID: "",
  showSubTable: false,
};

export default function SnowflakeDatabaseContainer(): JSX.Element {
  const globalDate = useAvailableGlobalDate();
  const theme = useTheme();

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  const durationEnum = createEnumParam(Object.values(DurationType));

  const [searchParamState, setSearchParamState] = useQueryParams({
    id: StringParam,
    accountFilter: withDefault(StringParam, AccountFilterTypes.COUNT),
    date_range: withDefault(JsonParam, []),
    duration_type: withDefault(durationEnum, DurationType.LAST_THIRTY_DAYS),
  });

  const dateRange = globalDate.date
    ? globalDate.date
    : getCubeDateRangeFromDurationType(searchParamState.duration_type);

  //
  // Queries
  //

  const spendSummary = useGetSpendSummaries({
    dataSource: DataSource.BILLING,
    queryFilters: [
      {
        name: "vendor",
        operator: Operator.EQUALS,
        values: ["Snowflake"],
      },
      {
        name: "skuDescription",
        operator: Operator.CONTAINS,
        values: ["storage", "reader storage"],
      },
    ],
  });

  const { data: currentSnowflakeMtdSpend, isLoading: isLoadingSpend } =
    spendSummary[0];

  const { data: lastMonthSnowflakeSpend, isLoading: isLoadingLastMonthSpend } =
    spendSummary[1];

  const { data: lastSnowflakeMtdSpend, isLoading: isLoadingMonthlySpend } =
    spendSummary[2];

  const isLoadingSpendSummary =
    isLoadingSpend || isLoadingMonthlySpend || isLoadingLastMonthSpend;

  const { data: usageBytesData } = useGetSnowflakeDatabaseUsageBytes();

  const { data: costData = [], isLoading: isLoadingCostData } = useGetRawData({
    dataSource: DataSource.BILLING,
    dateRange,
    dimensions: ["projectId"],
    granularity: TimeGranularity.DAY,
    measures: ["cost"],
    queryFilters: [
      {
        name: "vendor",
        operator: Operator.EQUALS,
        values: ["Snowflake"],
      },
      {
        name: "skuDescription",
        operator: Operator.CONTAINS,
        values: ["storage", "reader storage"],
      },
    ],
  });

  const { data: accountData = [], isLoading: isLoadingAccountData } =
    useGetRawData({
      dataSource: DataSource.SNOWFLAKE_DATABASE_USAGE,
      dateRange,
      dimensions: ["accountName"],
      granularity: TimeGranularity.DAY,
      measures:
        searchParamState.accountFilter === AccountFilterTypes.COUNT
          ? ["totalDistinctDatabases"]
          : [
              "averagePerDatabaseDailyDatabaseBytes",
              "averagePerDatabaseDailyFailsafeBytes",
            ],
    });

  const { data: usageData = [], isLoading: isLoadingDatabaseUsage } =
    useGetSnowflakeDatabaseUsage({ dateRange });

  //
  // Render
  //

  const filteredTableData = usageData.filter((db) => {
    if (state.searchText.length === 0) return true;

    const searchText = state.searchText.toLowerCase();

    return (
      db.accountName.toLowerCase().includes(searchText) ||
      db.databaseName.toLowerCase().includes(searchText) ||
      db.databaseOwner.toLowerCase().includes(searchText)
    );
  });

  const databaseUsageOptions = {
    [AccountFilterTypes.BYTES]: copyText.snowflakeDatabaseByAcccountBytesLabel,
    [AccountFilterTypes.COUNT]: copyText.snowflakeDatabaseByAccountCountLabel,
  };

  const databaseByAccountOptions = [
    {
      label: copyText.snowflakeDatabaseByAccountCountLabel,
      value: AccountFilterTypes.COUNT,
      onClick: () =>
        setSearchParamState({ accountFilter: AccountFilterTypes.COUNT }),
    },
    {
      label: copyText.snowflakeDatabaseByAcccountBytesLabel,
      value: AccountFilterTypes.BYTES,
      onClick: () =>
        setSearchParamState({ accountFilter: AccountFilterTypes.BYTES }),
    },
  ];

  const accountDataCountMeasure = [
    {
      name: "totalDistinctDatabases",
      unit: UnitType.STANDARD,
    },
  ];

  const accountDataBytesMeasures = [
    {
      name: "averagePerDatabaseDailyDatabaseBytes",
      unit: UnitType.BYTES,
    },
    {
      name: "averagePerDatabaseDailyFailsafeBytes",
      unit: UnitType.BYTES,
    },
  ];

  const aggregateCostKeys = {
    projectId: copyText.snowflakeDatabaseAccountNameKey,
  };

  return (
    <Box paddingTop={theme.space_md}>
      <Flex
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_2}
        height={200}
        marginBottom={theme.space_lg}
        padding={theme.space_lg}
      >
        <SnowflakeDatabaseMeters
          avgBytes={usageBytesData?.databaseBytes ?? 0}
          avgFailsafeBytes={usageBytesData?.failsafeBytes ?? 0}
          currentMTDSpend={currentSnowflakeMtdSpend?.grossCost ?? 0}
          isLoadingSpend={isLoadingSpendSummary}
          lastMonthSpend={lastMonthSnowflakeSpend?.grossCost ?? 0}
          lastMTDSpend={lastSnowflakeMtdSpend?.grossCost ?? 0}
        />
      </Flex>
      <Flex
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_1}
        justifyContent="flex-end"
        marginBottom={theme.space_lg}
        padding={theme.space_md}
      >
        <DateRangeControls
          dateRange={dateRange}
          durationType={searchParamState.duration_type}
          hiddenOptions={[
            DurationType.LAST_NINETY_DAYS,
            DurationType.QUARTER_TO_DATE,
            DurationType.YEAR_TO_DATE,
          ]}
          onChangeDateRange={(durationType, dateRange) => {
            setSearchParamState({
              duration_type: durationType,
              date_range: [dateRange?.[0] ?? null, dateRange?.[1] ?? null],
            });
            return;
          }}
        />
      </Flex>

      <Flex height={500} marginBottom={theme.space_lg}>
        <Box
          backgroundColor={theme.panel_backgroundColor}
          borderRadius={theme.borderRadius_2}
          flex={1}
          height="100%"
          marginRight={theme.space_lg}
          padding={theme.space_md}
          width="50%"
        >
          <Flex marginBottom={theme.space_md}>
            <Text fontSize={theme.h3_fontSize}>
              {copyText.snowflakeDatabaseAggregateCostChartTitle}
            </Text>
          </Flex>
          <Box height={400} paddingVertical={theme.space_md}>
            <StackedBarChart
              data={costData}
              disableDrilldown
              dimensions={[{ name: "projectId" }]}
              limit={10}
              isLoading={isLoadingCostData}
              measures={[{ name: "cost", unit: UnitType.CURRENCY }]}
              readableKeys={aggregateCostKeys}
              showLegend
              showTooltip
              timeSeriesGranularity={TimeGranularity.DAY}
              emptyPlaceholderText={copyText.snowflakeDatabaseNoDataLabel}
              xAxisKey="timestamp"
            />
          </Box>
        </Box>

        <Box
          backgroundColor={theme.panel_backgroundColor}
          borderRadius={theme.borderRadius_2}
          flex={1}
          height="100%"
          padding={theme.space_md}
          width="50%"
        >
          <Flex justifyContent="space-between" marginBottom={theme.space_md}>
            <Text fontSize={theme.h3_fontSize}>
              {copyText.snowflakeDatabaseByAccountChartTitle}
            </Text>
            <Dropdown
              defaultSelectedOption={databaseByAccountOptions[0]}
              options={databaseByAccountOptions}
              placement="bottom-end"
            >
              <Button
                iconEnd={<Icon icon={faChevronDown} />}
                secondary
                size="small"
                width={140}
              >
                {databaseUsageOptions[searchParamState.accountFilter]}
              </Button>
            </Dropdown>
          </Flex>

          <Box height={400} paddingVertical={theme.space_md}>
            <StackedBarChart
              data={accountData}
              disableDrilldown
              dimensions={[{ name: "accountName" }]}
              isLoading={isLoadingAccountData}
              measures={
                searchParamState.accountFilter === AccountFilterTypes.COUNT
                  ? accountDataCountMeasure
                  : accountDataBytesMeasures
              }
              showLegend
              showTooltip
              timeSeriesGranularity={TimeGranularity.DAY}
              emptyPlaceholderText={copyText.noDataPlaceholderMessage}
              xAxisKey="timestamp"
            />
          </Box>
        </Box>
      </Flex>

      <Flex
        justifyContent="flex-end"
        alignItems="center"
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_1}
        marginBottom={theme.space_lg}
        padding={theme.space_md}
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Box width={300}>
            <TextInput
              iconEnd={
                <Icon color={theme.text_color_secondary} icon={faSearch} />
              }
              placeholder={copyText.searchInputPlaceholder}
              size="large"
              value={state.searchText}
              onChange={(e) =>
                mergeState({ searchText: e.target.value.toLowerCase() })
              }
            />
          </Box>
          <CSVLink
            data={formatCSV(usageData)}
            filename={`${copyText.snowflakeCSVExportTitle}-${formatDate(
              new Date(),
              "MM-dd-yyyy"
            )}`}
          >
            <Button
              iconStart={<IconExport />}
              primary
              size="small"
              marginLeft={theme.space_md}
            >
              {copyText.exportButtonLabel}
            </Button>
          </CSVLink>
        </Flex>
      </Flex>

      <Box>
        <SnowflakeDatabaseUsageTable
          databases={filteredTableData}
          isLoading={isLoadingDatabaseUsage}
        />
      </Box>
    </Box>
  );
}

function formatCSV(data: DatabaseUsage[]): CSVDatabaseUsage[] {
  return data.map((database) => ({
    accountName: database.accountName,
    databaseID: database.databaseID,
    databaseName: database.databaseName,
    databaseOwner: database.databaseOwner,
    avgBytes: prettyBytes(database.averagePerDatabaseDailyDatabaseBytes),
    avgFailsafeBytes: prettyBytes(
      database.averagePerDatabaseDailyFailsafeBytes
    ),
    deletionTime: database.deletionTime,
    lastAlteredTime: database.lastAlteredTime,
    retentionDays: database.retentionDays,
    comment: database.comment,
  }));
}
