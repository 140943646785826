import { DateHelper } from "@/lib/dates";
import { createStructParam } from "@/lib/use-query-params";
import DateRangeControls from "@/ui-lib/components/DateRangeControls";
import Dropdown from "@/ui-lib/components/Dropdown";
import Grid from "@/ui-lib/components/Grid";
import Modal from "@/ui-lib/components/Modal";
import { DateRange } from "@/utils/dates";
import { useDebounce } from "@/utils/timers";
import { useTheme } from "@emotion/react";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { useQueryClient } from "@tanstack/react-query";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import { getDateRangeFromDurationType } from "@ternary/api-lib/analytics/utils";
import { UnitType } from "@ternary/api-lib/constants/analytics";
import {
  ChartType,
  DataSource,
  DurationType,
  Operator,
  TimeGranularity,
} from "@ternary/api-lib/constants/enums";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import EmptyPlaceholder from "@ternary/api-lib/ui-lib/components/EmptyPlaceholder";
import useReferenceIfEqual from "@ternary/api-lib/ui-lib/hooks/useReferenceIfEqual";
import AreaChart from "@ternary/web-ui-lib/charts/AreaChart";
import StackedBarChart from "@ternary/web-ui-lib/charts/StackedBarChart";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { differenceInHours, endOfDay, format } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import {
  ArrayParam,
  DateParam,
  DecodedValueMap,
  JsonParam,
  createEnumParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import { z } from "zod";
import useGetRawData from "../../../../api/analytics/useGetRawData";
import InsightsSelector from "../../../../components/InsightsSelector";
import useAvailableGlobalDate from "../../../../hooks/useAvailableGlobalDate";
import { useCSVDownloader } from "../../../../ui-lib/components/CSVDownloader";
import IconExport from "../../../../ui-lib/icons/IconExport";
import copyText from "../../copyText";
import useGetAzureVMComputeInstanceGroups from "../hooks/useGetAzureVMComputeInstanceGroups";
import useGetAzureVMComputeInstances from "../hooks/useGetAzureVMComputeInstances";
import useGetAzureVMComputeSpendSummaries from "../hooks/useGetAzureVMComputeSpendSummaries";
import {
  AzureComputeInstanceGroupFilters,
  AzureVMComputeInstance,
  AzureVMComputeInstanceGroup,
  azureVMComputeDimensions,
  azureVMComputeMeasures,
} from "../types";
import AzureVMComputeInstanceGroupTable from "./AzureVMComputeInstanceGroupTable";
import AzureVMComputeInstanceGroupTableControls from "./AzureVMComputeInstanceGroupTableControls";
import AzureVMComputeInstanceMeters from "./AzureVMComputeInstanceMeters";
import AzureVMComputeInstanceTable from "./AzureVMComputeInstanceTable";

const AzureComputeCostChartOption = {
  CATEGORY: "CATEGORY",
  FAMILY: "FAMILY",
  LOCATION: "LOCATION",
  PROJECT_ID: "PROJECT_ID",
} as const;

type AzureComputeCostChartOption =
  (typeof AzureComputeCostChartOption)[keyof typeof AzureComputeCostChartOption];

const AzureComputeUsageChartOption = {
  DISK: "DISK",
  MEMORY: "MEMORY",
  NETWORK: "NETWORK",
  VCPU: "VCPU",
} as const;

type AzureComputeUsageChartOption =
  (typeof AzureComputeUsageChartOption)[keyof typeof AzureComputeUsageChartOption];

export const AzureComputeCategoryOption = {
  BLOCK: "BLOCK",
  INSTANCE: "INSTANCE",
} as const;

export type AzureComputeCategoryOption =
  (typeof AzureComputeCategoryOption)[keyof typeof AzureComputeCategoryOption];

type Interaction =
  | AzureVMComputeInstanceGroupTable.Interaction
  | AzureVMComputeInstanceGroupTableControls.Interaction;

type QueryParams = DecodedValueMap<typeof queryParamConfigMap>;

type QueryParamState = {
  dateRange: DateRange;
  dateRangeGranularity: TimeGranularity;
  duration: DurationType;
  instanceGroupFilters: z.infer<typeof instanceGroupFiltersStruct>;
  selectedCostChartOption: AzureComputeCostChartOption;
  selectedInstanceGroup: z.infer<typeof selectedInstanceGroupStruct> | null;
  selectedCategoryGroup: AzureComputeCategoryOption;
  selectedUsageChartOption: AzureComputeUsageChartOption;
};

const instanceGroupFiltersDefault = {
  blockStorageClass: null,
  blockStorageType: null,
  family: null,
  instanceType: null,
  location: null,
  operatingSystem: null,
  projectId: null,
};

const instanceGroupFiltersStruct = z.object({
  blockStorageClass: z.nullable(z.string()),
  blockStorageType: z.nullable(z.string()),
  family: z.nullable(z.string()),
  instanceType: z.nullable(z.string()),
  location: z.nullable(z.string()),
  operatingSystem: z.nullable(z.string()),
  projectId: z.nullable(z.string()),
});

const selectedInstanceGroupStruct = z.object({
  blockStorageClass: z.string(),
  blockStorageType: z.string(),
  family: z.string(),
  instanceType: z.string(),
  location: z.string(),
  operatingSystem: z.string(),
  projectId: z.string(),
});

const costChartOptionEnum = createEnumParam(
  Object.values(AzureComputeCostChartOption)
);
const categoryTableOptionEnum = createEnumParam(
  Object.values(AzureComputeCategoryOption)
);
const durationEnum = createEnumParam(Object.values(DurationType));
const usageChartOptionEnum = createEnumParam(
  Object.values(AzureComputeUsageChartOption)
);

const queryParamConfigMap = {
  date_range_end: DateParam,
  date_range_start: DateParam,
  duration: withDefault(durationEnum, DurationType.LAST_THIRTY_DAYS),
  instance_table_sort: JsonParam,
  instance_group_filters: createStructParam(instanceGroupFiltersStruct),
  selected_category: withDefault(
    categoryTableOptionEnum,
    AzureComputeCategoryOption.INSTANCE
  ),
  selected_cost_chart_option: withDefault(
    costChartOptionEnum,
    AzureComputeCostChartOption.PROJECT_ID
  ),
  selected_group: createStructParam(selectedInstanceGroupStruct),
  selected_usage_chart_option: withDefault(
    usageChartOptionEnum,
    AzureComputeUsageChartOption.VCPU
  ),
  selected_usage_chart_utilization: ArrayParam,
};

export default function AzureVMComputeContainer() {
  const theme = useTheme();
  const globalDate = useAvailableGlobalDate();
  const queryClient = useQueryClient();

  //
  // STATE
  //
  const [queryParams, setQueryParams] = useQueryParams(queryParamConfigMap);

  const queryParamState = useReferenceIfEqual(getQueryParamState(queryParams));

  const [searchText, setSearchText] = useState("");

  const debouncedSearchText = useDebounce(searchText);

  const { downloadCSV, csvElement } = useCSVDownloader();

  //
  // QUERIES
  //

  const dateRange = globalDate.date ?? queryParamState.dateRange;

  const costDimensions = getCostDimensionsFromOption(
    queryParamState.selectedCostChartOption
  );

  const costMeasures = [azureVMComputeMeasures.cost];

  const usageMeasures = getUsageMeasuresFromOption(
    queryParamState.selectedUsageChartOption
  );

  const { data: costChartData = [], isFetching: isLoadingCostChartData } =
    useGetRawData({
      dataSource: DataSource.AZURE_COMPUTE_VISIBILITY,
      dateRange,
      dimensions: costDimensions,
      granularity: queryParamState.dateRangeGranularity,
      measures: costMeasures,
      queryFilters: [
        ...getInstanceGroupQueryFilters(queryParamState.instanceGroupFilters),
        ...(queryParamState.selectedCostChartOption ===
        AzureComputeCostChartOption.FAMILY
          ? [
              {
                name: "family",
                operator: Operator.SET,
              },
            ]
          : []),
      ],
    });

  const AzureVMComputeSpendSummaries = useGetAzureVMComputeSpendSummaries({});

  const [{ data: currentMTD }, { data: lastMonthFull }, { data: lastMTD }] =
    AzureVMComputeSpendSummaries;

  const isLoadingSpendSummaries = AzureVMComputeSpendSummaries.some(
    (summary) => summary.isFetching
  );

  const { data: usageChartData = [], isFetching: isLoadingUsageChartData } =
    useGetRawData({
      dataSource: DataSource.AZURE_COMPUTE_VISIBILITY,
      dateRange,
      granularity: queryParamState.dateRangeGranularity,
      measures: usageMeasures,
      queryFilters: getInstanceGroupQueryFilters(
        queryParamState.instanceGroupFilters
      ),
    });

  const { data: instanceGroups, isFetching: isLoadingInstanceGroups } =
    useGetAzureVMComputeInstanceGroups({
      dateRange,
      dimensions: getinstanceGroupDimensions(
        queryParamState.selectedCategoryGroup
      ),
      queryFilters: [
        ...getInstanceGroupQueryFilters(queryParamState.instanceGroupFilters),
        {
          name: azureVMComputeDimensions.computeCategory,
          operator:
            queryParamState.selectedCategoryGroup ===
            AzureComputeCategoryOption.BLOCK
              ? Operator.EQUALS
              : Operator.NOT_EQUALS,
          values: ["Block Storage"],
        },
      ],
    });

  const { data: instances = [], isFetching: isLoadingInstances } =
    useGetAzureVMComputeInstances(
      {
        dateRange,
        queryFilters: queryParamState.selectedInstanceGroup
          ? [
              ...getSelectedInstanceGroupQueryFilters(
                queryParamState.selectedInstanceGroup
              ),
            ]
          : [],
      },
      { enabled: Boolean(queryParamState.selectedInstanceGroup) }
    );

  const {
    data: allInstances = [],
    isFetching: isLoadingAllInstances,
    refetch: fetchAllInstances,
  } = useGetAzureVMComputeInstances(
    { dateRange, queryFilters: [] },
    { enabled: false, refetchInterval: false }
  );

  function clearAllInstancesQuery() {
    queryClient.removeQueries({ queryKey: ["azureComputeInstancesCSV"] });
  }

  //
  // MODIFIED QUERY DATA
  //

  const filteredInstanceGroups = useMemo(() => {
    const result = getFilteredInstanceGroups({
      allInstanceGroups: instanceGroups ?? [],
      cateagoryGroupFilters: queryParamState.selectedCategoryGroup,
      instanceGroupFilters: queryParamState.instanceGroupFilters,
      searchText: debouncedSearchText,
    });
    return result;
  }, [
    debouncedSearchText,
    queryParamState.instanceGroupFilters,
    queryParamState.selectedCategoryGroup,
    instanceGroups,
  ]);

  const instanceGroupCSVData = useMemo(
    () => getInstanceGroupCSVData(filteredInstanceGroups),
    [filteredInstanceGroups]
  );

  const instanceCSVData = useMemo(
    () => getInstanceCSVData(instances),
    [instances]
  );

  //
  // EFFECTS
  //

  useEffect(() => {
    if (allInstances.length === 0) {
      return;
    }

    const allInstancesCSVData = getInstanceCSVData(allInstances);

    downloadCSV({
      data: allInstancesCSVData.rows,
      fileName: `azure-all-compute-instances-${format(
        new Date(),
        "MM-dd-yyyy"
      )}`,
      headers: allInstancesCSVData.headers,
    });

    // clear allInstances query from the cache.
    clearAllInstancesQuery();
  }, [allInstances]);

  //
  // INTERACTIONS
  //

  function handleInteraction(interaction: Interaction) {
    switch (interaction.type) {
      case AzureVMComputeInstanceGroupTable.INTERACTION_FILTER_CLICKED: {
        const nextFilters = { ...queryParamState.instanceGroupFilters };

        nextFilters[interaction.filterKey] = interaction.filterValue;

        setQueryParams({ instance_group_filters: nextFilters });
        break;
      }
      case AzureVMComputeInstanceGroupTableControls.INTERACTION_FILTER_DROPDOWN_CLICKED: {
        setQueryParams({ selected_category: interaction.categoryKey });
        break;
      }
      case AzureVMComputeInstanceGroupTableControls.INTERACTION_DOWNLOAD_INSTANCES_CLICKED: {
        fetchAllInstances();
        break;
      }
      case AzureVMComputeInstanceGroupTableControls.INTERACTION_REMOVE_FILTER_CLICKED: {
        const nextFilters = { ...queryParamState.instanceGroupFilters };

        nextFilters[interaction.filterKey] = null;

        if (Object.values(nextFilters).every((value) => value === null)) {
          setQueryParams({ instance_group_filters: null });
        } else {
          setQueryParams({ instance_group_filters: nextFilters });
        }
        break;
      }
      case AzureVMComputeInstanceGroupTableControls.INTERACTION_SEARCH_TEXT_UPDATED: {
        setSearchText(interaction.searchText);
        break;
      }
    }
  }

  // If demo tenant, must pass in absolute date range to override date range snapshot in the reportContentContainer
  const costChartReportSnapshot = {
    chartType: ChartType.STACKED_BAR,
    dateRange:
      queryParamState.duration === DurationType.CUSTOM || globalDate
        ? queryParamState.dateRange
        : null,
    dataSource: DataSource.AZURE_COMPUTE_VISIBILITY,
    dimensions: costDimensions,
    durationType: queryParamState.duration,
    isFiscalMode: false,
    fillMissingDates: true,
    fiscalPeriodMap: null,
    granularity: queryParamState.dateRangeGranularity,
    measures: costMeasures,
    queryFilters: [
      {
        name: costDimensions[0],
        operator: Operator.NOT_EQUALS,
        values: ["null"],
      },
    ],
    name: copyText.awsComputeCostReportSnapshotName,
    xAxisKey: "timestamp",
  };

  const usageChartReportSnapshot = {
    chartType: ChartType.AREA,
    dateRange:
      queryParamState.duration === DurationType.CUSTOM || globalDate
        ? queryParamState.dateRange
        : null,
    dataSource: DataSource.AZURE_COMPUTE_VISIBILITY,
    dimensions: [],
    durationType: queryParamState.duration,
    isFiscalMode: false,
    fillMissingDates: true,
    fiscalPeriodMap: null,
    granularity: queryParamState.dateRangeGranularity,
    measures: usageMeasures,
    name: copyText.awsComputeUsageReportSnapshotName,
    xAxisKey: "timestamp",
  };

  function renderInstancesTableModal() {
    if (!queryParamState.selectedInstanceGroup) return null;

    const instanceGroupProjectID =
      queryParamState.selectedInstanceGroup.projectId;

    const subtitleCopy = [
      azureVMComputeDimensions.family,
      azureVMComputeDimensions.instanceType,
      azureVMComputeDimensions.operatingSystem,
      azureVMComputeDimensions.location,
      azureVMComputeDimensions.blockStorageClass,
      azureVMComputeDimensions.blockStorageType,
    ]
      .map((key) => {
        const copyTextKey: keyof typeof copyText = `azureComputeTableHeader_${key}`;
        const fieldName = copyText[copyTextKey];
        const value = queryParamState.selectedInstanceGroup?.[key];
        const displayValue = !value ? "null" : value;

        return `${fieldName} - ${displayValue}`;
      })
      .join(", ");

    return (
      <Modal
        isOpen
        showCloseButton
        onClose={() =>
          setQueryParams({
            instance_table_sort: null,
            selected_group: null,
          })
        }
        minWidth={1100}
      >
        <Modal.Header>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            marginLeft={theme.space_sm}
            width="100%"
          >
            <Box>
              <Text fontSize={theme.h4_fontSize}>
                {copyText.azureComputeInstancesTableTitle.replace(
                  "%PROJECT_ID%",
                  instanceGroupProjectID
                )}
              </Text>

              <Text appearance="h5" color={theme.text_color_secondary}>
                {subtitleCopy}
              </Text>
            </Box>

            {instanceCSVData.rows.length > 0 && (
              <CSVLink
                data={instanceCSVData.rows}
                headers={instanceCSVData.headers}
                filename={`azure-compute-instances-${format(
                  new Date(),
                  "MM-dd-yyyy"
                )}`}
              >
                <Button
                  iconStart={<IconExport />}
                  marginRight={theme.space_sm}
                  secondary
                  size="small"
                >
                  {copyText.exportButtonLabel}
                </Button>
              </CSVLink>
            )}
          </Flex>
        </Modal.Header>
        <Modal.Body>
          <AzureVMComputeInstanceTable
            instances={instances}
            isLoadingInstances={isLoadingInstances}
            selectedCategory={queryParamState.selectedCategoryGroup}
          />
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <Box width="100%" paddingTop={theme.space_md}>
      <Box
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_2}
        marginBottom={theme.space_lg}
        padding={theme.space_md}
      >
        <AzureVMComputeInstanceMeters
          isLoading={isLoadingSpendSummaries}
          lastMonthSpend={lastMonthFull?.totalCost ?? 0}
          lastMTDSpend={lastMTD?.totalCost ?? 0}
          thisMTDSpend={currentMTD?.totalCost ?? 0}
        />
      </Box>
      <Flex
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_1}
        justifyContent="flex-end"
        marginBottom={theme.space_lg}
        padding={theme.space_md}
      >
        <DateRangeControls
          dateRange={dateRange}
          durationType={queryParamState.duration}
          hiddenOptions={[
            DurationType.LAST_NINETY_DAYS,
            DurationType.QUARTER_TO_DATE,
            DurationType.YEAR_TO_DATE,
          ]}
          maxDate={new DateHelper().date}
          onChangeDateRange={(duration, newDateRange) => {
            setQueryParams({
              duration,
              ...(newDateRange && newDateRange[0] && newDateRange[1]
                ? {
                    date_range_start: newDateRange[0],
                    date_range_end: newDateRange[1],
                  }
                : {
                    date_range_start: null,
                    date_range_end: null,
                  }),
            });
          }}
        />
      </Flex>

      <Grid
        gridColumnGap={theme.space_lg}
        gridTemplateColumns={`repeat(2, calc(50% - (${theme.space_lg} / 2) ))`}
      >
        <InsightsSelector
          resourceName={copyText.awsComputeCostReportSnapshotName}
          reportSnapshot={costChartReportSnapshot}
        >
          <Flex
            backgroundColor={theme.panel_backgroundColor}
            borderRadius={theme.borderRadius_2}
            direction="column"
            height={500}
            padding={theme.space_md}
          >
            <Flex justifyContent="space-between" paddingBottom={theme.space_md}>
              <Text fontSize={theme.h3_fontSize}>
                {copyText.awsComputeOptimizationsChartTitleCost}
              </Text>

              {/* COST DROPDOWN */}
              <Dropdown
                options={costChartOptions.map((option) => ({
                  ...option,
                  onClick: () =>
                    setQueryParams({
                      selected_cost_chart_option: option.value,
                    }),
                }))}
                placement="bottom-end"
                selectedOption={
                  costChartOptions.find(
                    (option) =>
                      option.value === queryParamState.selectedCostChartOption
                  ) ?? costChartOptions[0]
                }
              >
                <Button
                  iconEnd={<Icon icon={faChevronDown} />}
                  secondary
                  size="small"
                  width={140}
                >
                  {getOptionLabel(queryParamState.selectedCostChartOption)}
                </Button>
              </Dropdown>
            </Flex>

            {/* COST CHART */}
            <Box flex="1 0 0">
              <StackedBarChart
                data={costChartData}
                dimensions={costDimensions.map((dimension) => ({
                  name: dimension,
                  isDate: false,
                }))}
                isLoading={isLoadingCostChartData}
                maxGroupings={7}
                measures={costMeasures.map(getMeasureWithUnit)}
                showLegend
                showTooltip
                timeSeriesGranularity={queryParamState.dateRangeGranularity}
                xAxisKey="timestamp"
              />
            </Box>
          </Flex>
        </InsightsSelector>
        <InsightsSelector
          resourceName={copyText.awsComputeUsageReportSnapshotName}
          reportSnapshot={usageChartReportSnapshot}
        >
          <Flex
            backgroundColor={theme.panel_backgroundColor}
            borderRadius={theme.borderRadius_2}
            direction="column"
            height={500}
            padding={theme.space_md}
          >
            <Flex justifyContent="space-between" paddingBottom={theme.space_md}>
              <Text fontSize={theme.h3_fontSize}>
                {copyText.awsComputeOptimizationsChartTitleUsage}
              </Text>

              <Box flex="0 1 100%" />

              {/* USAGE DROPDOWN */}
              <Dropdown
                options={usageChartOptions.map((option) => ({
                  ...option,
                  onClick: () =>
                    setQueryParams({
                      selected_usage_chart_option: option.value,
                    }),
                }))}
                placement="bottom-end"
                selectedOption={
                  usageChartOptions.find(
                    (option) =>
                      option.value === queryParamState.selectedUsageChartOption
                  ) ?? usageChartOptions[0]
                }
              >
                <Button
                  iconEnd={<Icon icon={faChevronDown} />}
                  secondary
                  size="small"
                  width={140}
                >
                  {getOptionLabel(queryParamState.selectedUsageChartOption)}
                </Button>
              </Dropdown>
            </Flex>

            {/* USAGE CHART */}
            <Box flex="1 0 0">
              <AreaChart
                data={usageChartData}
                hideTotal
                isLoading={isLoadingUsageChartData}
                measures={usageMeasures.map(getMeasureWithUnit)}
                readableKeys={readableMeasures}
                showLegend
                showTooltip
                stacked
                timeSeriesGranularity={queryParamState.dateRangeGranularity}
                xAxisKey="timestamp"
              />
            </Box>
          </Flex>
        </InsightsSelector>
      </Grid>

      <Box
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_1}
        marginVertical={theme.space_lg}
        padding={theme.space_md}
      >
        <AzureVMComputeInstanceGroupTableControls
          csvData={instanceGroupCSVData}
          debouncedSearchText={debouncedSearchText}
          instanceGroupFilters={queryParamState.instanceGroupFilters}
          isLoadingAllInstances={isLoadingAllInstances}
          searchText={searchText}
          selectedCategory={queryParamState.selectedCategoryGroup}
          onInteraction={handleInteraction}
        />

        {csvElement}
      </Box>

      <Box width="100%" overflowX="auto">
        {isLoadingInstanceGroups ? (
          <EmptyPlaceholder loading={isLoadingInstanceGroups} />
        ) : (
          <AzureVMComputeInstanceGroupTable
            instanceGroups={filteredInstanceGroups}
            isLoadingInstanceGroups={isLoadingInstanceGroups}
            selectedCategory={queryParamState.selectedCategoryGroup}
            onInteraction={handleInteraction}
          />
        )}
      </Box>

      {renderInstancesTableModal()}
    </Box>
  );
}

// COST OPTIONS
const costChartOptions = [
  AzureComputeCostChartOption.CATEGORY,
  AzureComputeCostChartOption.FAMILY,
  AzureComputeCostChartOption.LOCATION,
  AzureComputeCostChartOption.PROJECT_ID,
].map((costOption) => ({
  label: getOptionLabel(costOption),
  value: costOption,
}));

// USAGE OPTIONS
const usageChartOptions = [
  AzureComputeUsageChartOption.VCPU,
  AzureComputeUsageChartOption.MEMORY,
  AzureComputeUsageChartOption.NETWORK,
  AzureComputeUsageChartOption.DISK,
].map((usageOption) => ({
  label: getOptionLabel(usageOption),
  value: usageOption,
}));

function getCostDimensionsFromOption(option: AzureComputeCostChartOption) {
  switch (option) {
    case AzureComputeCostChartOption.FAMILY:
      return [azureVMComputeDimensions.family];
    case AzureComputeCostChartOption.LOCATION:
      return [azureVMComputeDimensions.location];
    case AzureComputeCostChartOption.PROJECT_ID:
      return [azureVMComputeDimensions.projectId];
    case AzureComputeCostChartOption.CATEGORY:
      return [azureVMComputeDimensions.computeCategory];
    default: {
      const _exhaustiveCheck: never = option;
      return _exhaustiveCheck;
    }
  }
}

const measuresWithUnit: { name: string; unit: UnitType }[] = [
  { name: "productMemoryBytes", unit: UnitType.BYTES },
  { name: "productVCPU", unit: UnitType.STANDARD },
  { name: "cost", unit: UnitType.CURRENCY },
  { name: "absoluteCredits", unit: UnitType.CURRENCY },
  { name: "usageAmount", unit: UnitType.STANDARD },
  { name: "cpuUtilization", unit: UnitType.PERCENTAGE },
  { name: "ramAvailableBytes", unit: UnitType.BINARY_BYTES },
  { name: "receivedBytes", unit: UnitType.BINARY_BYTES },
  { name: "sentBytes", unit: UnitType.BINARY_BYTES },
  { name: "diskReadBytes", unit: UnitType.BINARY_BYTES },
  { name: "diskWriteBytes", unit: UnitType.BINARY_BYTES },
];

function getMeasureWithUnit(measure: string) {
  return (
    [...measuresWithUnit].find((other) => other.name === measure) ?? {
      name: measure,
      unit: UnitType.STANDARD,
    }
  );
}

type CSVData = {
  headers: { key: string; label: string }[];
  rows: Record<string, string | number>[];
};

const instanceGroupCSVAccessors = [
  "family",
  "instanceType",
  "operatingSystem",
  "blockStorageClass",
  "blockStorageType",
  "location",
  "projectId",
  "instanceCount",
  "usageAmount",
  "cost",
  "productVCPU",
  "cpuUtilizationMin",
  "cpuUtilizationAvg",
  "cpuUtilizationMax",
  "diskReadBytes",
  "diskWriteBytes",
  "productMemoryBytes",
  "ramAvailableBytesMin",
  "ramAvailableBytesAvg",
  "ramAvailableBytesMax",
  "receivedBytes",
  "sentBytes",
] as const;

function getInstanceGroupCSVData(
  groups: AzureVMComputeInstanceGroup[]
): CSVData {
  if (!groups.length) {
    return { headers: [], rows: [] };
  }

  const rows = groups.map((group) => ({
    cost: group.cost,
    cpuUtilizationAvg: group.cpuUtilizationAvg,
    cpuUtilizationMax: group.cpuUtilizationMax,
    cpuUtilizationMin: group.cpuUtilizationMin,
    diskReadBytes: group.diskReadBytes,
    diskWriteBytes: group.diskWriteBytes,
    family: group.family,
    blockStorageClass: group.blockStorageClass,
    blockStorageType: group.blockStorageType,
    instanceCount: group.instanceCount,
    instanceType: group.instanceType,
    location: group.location,
    operatingSystem: group.operatingSystem,
    productMemoryBytes: group.productMemoryBytes,
    productVCPU: group.productVCPU,
    projectId: group.projectId,
    ramAvailableBytesAvg: group.ramAvailableBytesAvg,
    ramAvailableBytesMax: group.ramAvailableBytesMax,
    ramAvailableBytesMin: group.ramAvailableBytesMin,
    receivedBytes: group.receivedBytes,
    sentBytes: group.sentBytes,
    usageAmount: group.usageAmount,
  }));

  const headers = instanceGroupCSVAccessors.map((csvAccessor) => {
    // ensure rows has a value for each accessor
    const key: keyof (typeof rows)[number] = csvAccessor;

    // ensure copyText has a value for each accessor
    const copyTextKey: keyof typeof copyText = `azureComputeTableHeader_${csvAccessor}`;
    const label = copyText[copyTextKey];

    return { key, label };
  });

  return { headers, rows };
}

const instanceCSVAccessors = [
  "instanceName",
  "instanceId",
  "projectId",
  "instanceType",
  "operatingSystem",
  "family",
  "location",
  "cost",
  "instanceType",
  "usageAmount",
  "productVCPU",
  "cpuUtilizationMin",
  "cpuUtilizationAvg",
  "cpuUtilizationMax",
  "receivedBytes",
  "sentBytes",
  "diskReadBytes",
  "diskWriteBytes",
  "productMemoryBytes",
  "ramAvailableBytesMin",
  "ramAvailableBytesAvg",
  "ramAvailableBytesMax",
] as const;

function getInstanceCSVData(instances: AzureVMComputeInstance[]): CSVData {
  if (!instances.length) {
    return { headers: [], rows: [] };
  }

  const rows = instances.map((instance) => ({
    cost: instance.cost,
    cpuUtilizationAvg: instance.cpuUtilizationAvg,
    cpuUtilizationMax: instance.cpuUtilizationMax,
    cpuUtilizationMin: instance.cpuUtilizationMin,
    diskReadBytes: instance.diskReadBytes,
    diskWriteBytes: instance.diskWriteBytes,
    family: instance.family,
    instanceId: instance.instanceId,
    instanceName: instance.instanceName,
    instanceType: instance.instanceType,
    location: instance.location,
    operatingSystem: instance.operatingSystem,
    productMemoryBytes: instance.productMemoryBytes,
    productVCPU: instance.productVCPU,
    projectId: instance.projectId,
    ramAvailableBytesAvg: instance.ramAvailableBytesAvg,
    ramAvailableBytesMax: instance.ramAvailableBytesMax,
    ramAvailableBytesMin: instance.ramAvailableBytesMin,
    receivedBytes: instance.receivedBytes,
    sentBytes: instance.sentBytes,
    usageAmount: instance.usageAmount,
  }));

  const headers = instanceCSVAccessors.map((csvAccessor) => {
    // ensure rows has a value for each accessor
    const key: keyof (typeof rows)[number] = csvAccessor;

    // ensure copyText has a value for each accessor
    const copyTextKey: keyof typeof copyText = `azureComputeTableHeader_${csvAccessor}`;
    const label = copyText[copyTextKey];

    return { key, label };
  });

  return { headers, rows };
}

type GetFilteredInstanceGroupsParams = {
  allInstanceGroups: AzureVMComputeInstanceGroup[];
  cateagoryGroupFilters: AzureComputeCategoryOption;
  instanceGroupFilters: AzureComputeInstanceGroupFilters;
  searchText: string | null;
};

function getFilteredInstanceGroups(
  params: GetFilteredInstanceGroupsParams
): AzureVMComputeInstanceGroup[] {
  const searchText = (params.searchText ?? "").toLowerCase().trim();

  return params.allInstanceGroups.filter((instanceGroup) => {
    if (
      !isInstanceGroupPassingFilters(
        params.cateagoryGroupFilters,
        instanceGroup,
        params.instanceGroupFilters
      )
    ) {
      return false;
    }

    if (!isSearchTextInInstanceGroup(instanceGroup, searchText)) {
      return false;
    }

    return true;
  });
}

function getSelectedInstanceGroupQueryFilters(
  selectedInstanceGroup: z.infer<typeof selectedInstanceGroupStruct>
): QueryFilter[] {
  return [
    azureVMComputeDimensions.family,
    azureVMComputeDimensions.instanceType,
    azureVMComputeDimensions.location,
    azureVMComputeDimensions.operatingSystem,
    azureVMComputeDimensions.projectId,
  ].map(
    (key): QueryFilter =>
      selectedInstanceGroup[key] === ""
        ? {
            name: key,
            operator: Operator.NOT_SET,
          }
        : {
            name: key,
            operator: Operator.EQUALS,
            values: [selectedInstanceGroup[key]],
          }
  );
}

function getinstanceGroupDimensions(
  selectedCategoryGroup: AzureComputeCategoryOption
) {
  if (selectedCategoryGroup === AzureComputeCategoryOption.BLOCK) {
    return [
      azureVMComputeDimensions.location,
      azureVMComputeDimensions.projectId,
      azureVMComputeDimensions.blockStorageClass,
      azureVMComputeDimensions.blockStorageType,
    ];
  } else {
    return [
      azureVMComputeDimensions.family,
      azureVMComputeDimensions.instanceType,
      azureVMComputeDimensions.operatingSystem,
      azureVMComputeDimensions.location,
      azureVMComputeDimensions.projectId,
    ];
  }
}

function getInstanceGroupQueryFilters(
  instanceGroupFilters: z.infer<typeof instanceGroupFiltersStruct>
) {
  const queryFilters: QueryFilter[] = [];

  [
    azureVMComputeDimensions.family,
    azureVMComputeDimensions.instanceType,
    azureVMComputeDimensions.blockStorageClass,
    azureVMComputeDimensions.blockStorageType,
    azureVMComputeDimensions.location,
    azureVMComputeDimensions.operatingSystem,
    azureVMComputeDimensions.projectId,
  ].forEach((key) => {
    const filterValue = instanceGroupFilters[key];

    if (filterValue === null) return;

    if (filterValue === "") {
      queryFilters.push({
        name: key,
        operator: Operator.NOT_SET,
      });

      return;
    }

    queryFilters.push({
      name: key,
      operator: Operator.EQUALS,
      values: [filterValue],
    });
  });

  return queryFilters;
}

function getOptionLabel(
  option: AzureComputeCostChartOption | AzureComputeUsageChartOption
) {
  return copyText[`azureVMComputeChartOptionLabel_${option}`];
}

function getQueryParamState(queryParams: QueryParams): QueryParamState {
  const dateRange =
    queryParams.date_range_start && queryParams.date_range_end
      ? [queryParams.date_range_start, queryParams.date_range_end]
      : getDateRangeFromDurationType(queryParams.duration);

  const dateRangeDurationInHours =
    dateRange[0] && dateRange[1]
      ? differenceInHours(endOfDay(dateRange[1]), dateRange[0])
      : 0;

  const isSmallDateRange =
    dateRangeDurationInHours > 0 && dateRangeDurationInHours <= 48;

  const dateRangeGranularity = isSmallDateRange
    ? TimeGranularity.HOUR
    : TimeGranularity.DAY;

  const selectedUsageChartUtilization: string[] = [];

  (queryParams.selected_usage_chart_utilization ?? []).forEach((value) => {
    if (!value) return;
    selectedUsageChartUtilization.push(value);
  });

  return {
    dateRange,
    dateRangeGranularity,
    duration: queryParams.duration,
    instanceGroupFilters:
      queryParams.instance_group_filters ?? instanceGroupFiltersDefault,
    selectedCostChartOption: queryParams.selected_cost_chart_option,
    selectedInstanceGroup: queryParams.selected_group ?? null,
    selectedCategoryGroup: queryParams.selected_category,
    selectedUsageChartOption: queryParams.selected_usage_chart_option,
  };
}

const readableMeasures = {
  productVCPU: "productVCPU",
  productMemoryBytes: "productMemoryBytes",
};

function getUsageMeasuresFromOption(option: AzureComputeUsageChartOption) {
  switch (option) {
    case AzureComputeUsageChartOption.VCPU: {
      return [azureVMComputeMeasures.productVCPU];
    }
    case AzureComputeUsageChartOption.MEMORY: {
      return [azureVMComputeMeasures.productMemoryBytes];
    }
    case AzureComputeUsageChartOption.NETWORK: {
      return [
        azureVMComputeMeasures.receivedBytes,
        azureVMComputeMeasures.sentBytes,
      ];
    }
    case AzureComputeUsageChartOption.DISK: {
      return [
        azureVMComputeMeasures.diskReadBytes,
        azureVMComputeMeasures.diskWriteBytes,
      ];
    }
    default: {
      const _exhaustiveCheck: never = option;
      return _exhaustiveCheck;
    }
  }
}

function isInstanceGroupPassingFilters(
  cateagoryGroupFilters: AzureComputeCategoryOption,
  instanceGroup: AzureVMComputeInstanceGroup,
  filters: AzureComputeInstanceGroupFilters
): boolean {
  let result = true;
  getinstanceGroupDimensions(cateagoryGroupFilters).map((catGroup) => {
    if (
      filters[catGroup] !== null &&
      instanceGroup[catGroup].toLowerCase().trim() !==
        filters[catGroup].toLowerCase().trim()
    ) {
      result = false;
    }
  });

  return result;
}

function isSearchTextInInstanceGroup(
  instanceGroup: AzureVMComputeInstanceGroup,
  searchText: string
): boolean {
  if (searchText === "") return true;

  const values = [
    instanceGroup.family,
    instanceGroup.instanceType,
    instanceGroup.operatingSystem,
    instanceGroup.blockStorageClass,
    instanceGroup.blockStorageType,
    instanceGroup.location,
    instanceGroup.projectId,
  ].map((value) => (value === "" ? "null" : value));

  return values.some((value) =>
    value.toLowerCase().trim().includes(searchText)
  );
}
