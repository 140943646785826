import { DateHelper } from "@/lib/dates";
import { createStructParam } from "@/lib/use-query-params";
import DateRangeControls from "@/ui-lib/components/DateRangeControls";
import { DateRange } from "@/utils/dates";
import { useDebounce } from "@/utils/timers";
import { useTheme } from "@emotion/react";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import { getDateRangeFromDurationType } from "@ternary/api-lib/analytics/utils";
import { UnitType } from "@ternary/api-lib/constants/analytics";
import {
  ChartType,
  DataSource,
  DurationType,
  Operator,
  TimeGranularity,
} from "@ternary/api-lib/constants/enums";
import AreaChart from "@ternary/api-lib/ui-lib/charts/AreaChart";
import StackedBarChart from "@ternary/api-lib/ui-lib/charts/StackedBarChart";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import useReferenceIfEqual from "@ternary/api-lib/ui-lib/hooks/useReferenceIfEqual";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import { differenceInHours, endOfDay } from "date-fns";
import React, { useMemo, useState } from "react";
import {
  ArrayParam,
  DateParam,
  DecodedValueMap,
  JsonParam,
  createEnumParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import { z } from "zod";
import InsightsSelector from "../../../../components/InsightsSelector";
import useAvailableGlobalDate from "../../../../hooks/useAvailableGlobalDate";
import Dropdown from "../../../../ui-lib/components/Dropdown";
import Grid from "../../../../ui-lib/components/Grid";
import copyText from "../../copyText";
import useGetAzureStorageBBData from "../hooks/useGetAzureStorageBBData";
import useGetAzureStorageSpendSummaries from "../hooks/useGetAzureStorageSpendSummaries";
import {
  AzureStorageBBDatum,
  AzureStorageBBDimension,
  AzureStorageBBMeasure,
  AzureStorageBBTableFilters,
  azureStorageBBDimensions,
  azureStorageBBMeasures,
} from "../types";
import AzureStorageBBMeters from "./AzureStorageBBMeters";
import AzureStorageBBTable from "./AzureStorageBBTable";
import AzureStorageBBTableControls from "./AzureStorageBBTableControls";

const AzureStorageBBCostChartOption = {
  ACCOUNT_ID: "ACCOUNT_ID",
  PRICING_MODEL: "PRICING_MODEL",
  PROJECT_ID: "PROJECT_ID",
  STORAGE_ACCOUNT: "STORAGE_ACCOUNT",
} as const;

type AzureStorageBBCostChartOption =
  (typeof AzureStorageBBCostChartOption)[keyof typeof AzureStorageBBCostChartOption];

const AzureStorageBBUsageChartOption = {
  BLOB_SIZE: "BLOB_SIZE",
  NETWORK: "NETWORK",
  TRANSACTIONS: "TRANSACTIONS",
  USAGE_AMOUNT: "USAGE_AMOUNT",
} as const;

type AzureStorageBBUsageChartOption =
  (typeof AzureStorageBBUsageChartOption)[keyof typeof AzureStorageBBUsageChartOption];

type Interaction =
  | AzureStorageBBTable.Interaction
  | AzureStorageBBTableControls.Interaction;

type QueryParams = DecodedValueMap<typeof queryParamConfigMap>;
type SelectedGroup = z.infer<typeof selectedGroupStruct> | null;

type QueryParamState = {
  tableFilters: AzureStorageBBTableFilters;
  dateRange: DateRange;
  dateRangeGranularity: TimeGranularity;
  duration: DurationType;
  selectedCostChartOption: AzureStorageBBCostChartOption;
  selectedGroup: SelectedGroup;
  selectedUsageChartOption: AzureStorageBBUsageChartOption;
};

const tableFiltersDefault = {
  [azureStorageBBDimensions.accountId]: null,
  [azureStorageBBDimensions.pricingModel]: null,
  [azureStorageBBDimensions.projectId]: null,
  [azureStorageBBDimensions.resourceId]: null,
  [azureStorageBBDimensions.skuDescription]: null,
  [azureStorageBBDimensions.storageAccount]: null,
};

const tableFiltersStruct = z.object({
  [azureStorageBBDimensions.accountId]: z.nullable(z.string()),
  [azureStorageBBDimensions.pricingModel]: z.nullable(z.string()),
  [azureStorageBBDimensions.projectId]: z.nullable(z.string()),
  [azureStorageBBDimensions.resourceId]: z.nullable(z.string()),
  [azureStorageBBDimensions.skuDescription]: z.nullable(z.string()),
  [azureStorageBBDimensions.storageAccount]: z.nullable(z.string()),
});

const selectedGroupStruct = z.object({
  accountId: z.string(),
  pricingModel: z.string(),
  projectId: z.string(),
  storageAccount: z.string(),
});

const costChartOptionEnum = createEnumParam(
  Object.values(AzureStorageBBCostChartOption)
);
const durationEnum = createEnumParam(Object.values(DurationType));
const usageChartOptionEnum = createEnumParam(
  Object.values(AzureStorageBBUsageChartOption)
);

const queryParamConfigMap = {
  date_range_end: DateParam,
  date_range_start: DateParam,
  duration: withDefault(durationEnum, DurationType.LAST_THIRTY_DAYS),
  instance_table_sort: JsonParam,
  filters: createStructParam(tableFiltersStruct),
  selected_cost_chart_option: withDefault(
    costChartOptionEnum,
    AzureStorageBBCostChartOption.PROJECT_ID
  ),
  selected_group: createStructParam(selectedGroupStruct),
  selected_usage_chart_option: withDefault(
    usageChartOptionEnum,
    AzureStorageBBUsageChartOption.BLOB_SIZE
  ),
  selected_usage_chart_utilization: ArrayParam,
};

const defaultCostChartData = [];
const defaultTableData = [];
const defaultUsageChartData = [];

export default function AzureStorageBBContainer() {
  const theme = useTheme();
  const globalDate = useAvailableGlobalDate();

  //
  // STATE
  //

  const [queryParams, setQueryParams] = useQueryParams(queryParamConfigMap);

  const queryParamState = useReferenceIfEqual(getQueryParamState(queryParams));

  const [searchText, setSearchText] = useState("");

  const debouncedSearchText = useDebounce(searchText);

  //
  // QUERIES
  //

  const dateRange = globalDate.date ?? queryParamState.dateRange;

  const costDimensions = getCostDimensionsFromOption(
    queryParamState.selectedCostChartOption
  );

  const costMeasures = [azureStorageBBMeasures.cost];

  const usageMeasures = getUsageMeasuresFromOption(
    queryParamState.selectedUsageChartOption
  );

  const {
    data: costChartData = defaultCostChartData,
    isFetching: isLoadingCostChartData,
  } = useGetAzureStorageBBData({
    dateRange,
    dimensions: costDimensions,
    filters: getChartQueryFilters(queryParamState.tableFilters),
    granularity: queryParamState.dateRangeGranularity,
    measures: [azureStorageBBMeasures.cost],
  });

  const azureStorageBBSpendSummaries = useGetAzureStorageSpendSummaries({});

  const [{ data: currentMTD }, { data: lastMonthFull }, { data: lastMTD }] =
    azureStorageBBSpendSummaries;

  const isLoadingSpendSummaries = azureStorageBBSpendSummaries.some(
    (summary) => summary.isFetching
  );

  const {
    data: usageChartData = defaultUsageChartData,
    isFetching: isLoadingUsageChartData,
  } = useGetAzureStorageBBData({
    dateRange,
    dimensions: [],
    filters: getChartQueryFilters(queryParamState.tableFilters),
    granularity: queryParamState.dateRangeGranularity,
    measures: [
      azureStorageBBMeasures.blobSizeBytes,
      azureStorageBBMeasures.bytesDownloaded,
      azureStorageBBMeasures.bytesUploaded,
      azureStorageBBMeasures.containerCount,
      azureStorageBBMeasures.cost,
      azureStorageBBMeasures.transactions,
      azureStorageBBMeasures.usageAmount,
    ],
  });

  const { data: tableData = defaultTableData, isFetching: isLoadingTableData } =
    useGetAzureStorageBBData({
      dateRange,
      dimensions: [
        azureStorageBBDimensions.projectId,
        azureStorageBBDimensions.storageAccount,
      ],
      measures: [
        azureStorageBBMeasures.blobSizeBytes,
        azureStorageBBMeasures.bytesDownloaded,
        azureStorageBBMeasures.bytesUploaded,
        azureStorageBBMeasures.containerCount,
        azureStorageBBMeasures.cost,
        azureStorageBBMeasures.transactions,
        azureStorageBBMeasures.usageAmount,
      ],
    });

  //
  // MODIFIED QUERY DATA
  //

  const filteredTableData = useMemo(() => {
    return getFilteredBBData({
      data: tableData ?? [],
      tableFilters: queryParamState.tableFilters,
      searchText: debouncedSearchText,
    });
  }, [debouncedSearchText, queryParamState.tableFilters, tableData]);

  const csvData = useMemo(
    () => getCSVData(filteredTableData),
    [filteredTableData]
  );

  //
  // INTERACTIONS
  //

  function handleInteraction(interaction: Interaction) {
    switch (interaction.type) {
      case AzureStorageBBTable.INTERACTION_FILTER_CLICKED: {
        const filters = { ...queryParamState.tableFilters };

        filters[interaction.filterKey] = interaction.filterValue;

        setQueryParams({ filters: filters });
        break;
      }
      case AzureStorageBBTableControls.INTERACTION_REMOVE_FILTER_CLICKED: {
        const nextFilters = { ...queryParamState.tableFilters };

        nextFilters[interaction.filterKey] = null;

        if (Object.values(nextFilters).every((value) => value === null)) {
          setQueryParams({ filters: null });
        } else {
          setQueryParams({ filters: nextFilters });
        }
        break;
      }
      case AzureStorageBBTableControls.INTERACTION_SEARCH_TEXT_UPDATED: {
        setSearchText(interaction.searchText);
        break;
      }
    }
  }

  const costChartReportSnapshot = {
    chartType: ChartType.STACKED_BAR,
    dateRange:
      queryParamState.duration === DurationType.CUSTOM || globalDate
        ? queryParamState.dateRange
        : null,
    dataSource: DataSource.AZURE_STORAGE_VISIBILITY,
    dimensions: costDimensions,
    durationType: queryParamState.duration,
    isFiscalMode: false,
    fillMissingDates: true,
    fiscalPeriodMap: null,
    granularity: queryParamState.dateRangeGranularity,
    measures: costMeasures,
    queryFilters: [
      {
        name: costDimensions[0],
        operator: Operator.NOT_EQUALS,
        values: ["null"],
      },
    ],
    name: copyText.azureStorageBBReportSnapshotNameCost,
    xAxisKey: "timestamp",
  };

  const usageChartReportSnapshot = {
    chartType: ChartType.AREA,
    dateRange:
      queryParamState.duration === DurationType.CUSTOM || globalDate
        ? queryParamState.dateRange
        : null,
    dataSource: DataSource.AZURE_STORAGE_VISIBILITY,
    dimensions: [],
    durationType: queryParamState.duration,
    isFiscalMode: false,
    fillMissingDates: true,
    fiscalPeriodMap: null,
    granularity: queryParamState.dateRangeGranularity,
    measures: usageMeasures,
    name: copyText.azureStorageBBReportSnapshotNameUsage,
    xAxisKey: "timestamp",
  };

  return (
    <Box width="100%" paddingTop={theme.space_md}>
      <Box
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_2}
        marginBottom={theme.space_lg}
        padding={theme.space_md}
      >
        <AzureStorageBBMeters
          isLoading={isLoadingSpendSummaries}
          lastMonthSpend={lastMonthFull?.totalCost ?? 0}
          lastMTDSpend={lastMTD?.totalCost ?? 0}
          thisMTDSpend={currentMTD?.totalCost ?? 0}
        />
      </Box>
      <Flex
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_1}
        justifyContent="flex-end"
        marginBottom={theme.space_lg}
        padding={theme.space_md}
      >
        <DateRangeControls
          dateRange={dateRange}
          durationType={queryParamState.duration}
          hiddenOptions={[
            DurationType.LAST_NINETY_DAYS,
            DurationType.QUARTER_TO_DATE,
            DurationType.YEAR_TO_DATE,
          ]}
          maxDate={new DateHelper().date}
          onChangeDateRange={(duration, newDateRange) => {
            setQueryParams({
              duration,
              ...(newDateRange && newDateRange[0] && newDateRange[1]
                ? {
                    date_range_start: newDateRange[0],
                    date_range_end: newDateRange[1],
                  }
                : {
                    date_range_start: null,
                    date_range_end: null,
                  }),
            });
          }}
        />
      </Flex>

      <Grid
        gridColumnGap={theme.space_lg}
        gridTemplateColumns={`repeat(2, calc(50% - (${theme.space_lg} / 2) ))`}
      >
        <InsightsSelector
          // TODO: ETHAN
          resourceName={copyText.awsComputeCostReportSnapshotName}
          reportSnapshot={costChartReportSnapshot}
        >
          <Flex
            backgroundColor={theme.panel_backgroundColor}
            borderRadius={theme.borderRadius_2}
            direction="column"
            height={500}
            padding={theme.space_md}
          >
            <Flex justifyContent="space-between" paddingBottom={theme.space_md}>
              <Text fontSize={theme.h3_fontSize}>
                {copyText.awsComputeOptimizationsChartTitleCost}
              </Text>

              <Dropdown
                options={costChartOptions.map((option) => ({
                  ...option,
                  onClick: () =>
                    setQueryParams({
                      selected_cost_chart_option: option.value,
                    }),
                }))}
                placement="bottom-end"
                selectedOption={
                  costChartOptions.find(
                    (option) =>
                      option.value === queryParamState.selectedCostChartOption
                  ) ?? costChartOptions[0]
                }
              >
                <Button
                  iconEnd={<Icon icon={faChevronDown} />}
                  secondary
                  size="small"
                  width={140}
                >
                  {getOptionLabel(queryParamState.selectedCostChartOption)}
                </Button>
              </Dropdown>
            </Flex>

            <Box flex="1 0 0">
              <StackedBarChart
                data={costChartData}
                dimensions={costDimensions.map((dimension) => ({
                  name: dimension,
                  isDate: false,
                }))}
                isLoading={isLoadingCostChartData}
                maxGroupings={7}
                measures={costMeasures.map(getMeasureWithUnit)}
                showLegend
                showTooltip
                timeSeriesGranularity={queryParamState.dateRangeGranularity}
                xAxisKey="timestamp"
              />
            </Box>
          </Flex>
        </InsightsSelector>

        <InsightsSelector
          resourceName={copyText.awsComputeUsageReportSnapshotName}
          reportSnapshot={usageChartReportSnapshot}
        >
          <Flex
            backgroundColor={theme.panel_backgroundColor}
            borderRadius={theme.borderRadius_2}
            direction="column"
            height={500}
            padding={theme.space_md}
          >
            <Flex justifyContent="space-between" paddingBottom={theme.space_md}>
              <Text fontSize={theme.h3_fontSize}>
                {copyText.awsComputeOptimizationsChartTitleUsage}
              </Text>

              <Box flex="0 1 100%" />

              <Dropdown
                options={usageChartOptions.map((option) => ({
                  ...option,
                  onClick: () =>
                    setQueryParams({
                      selected_usage_chart_option: option.value,
                    }),
                }))}
                placement="bottom-end"
                selectedOption={
                  usageChartOptions.find(
                    (option) =>
                      option.value === queryParamState.selectedUsageChartOption
                  ) ?? usageChartOptions[0]
                }
              >
                <Button
                  iconEnd={<Icon icon={faChevronDown} />}
                  secondary
                  size="small"
                  width={140}
                >
                  {getOptionLabel(queryParamState.selectedUsageChartOption)}
                </Button>
              </Dropdown>
            </Flex>

            <Box flex="1 0 0">
              <AreaChart
                data={usageChartData}
                hideTotal
                isLoading={isLoadingUsageChartData}
                measures={usageMeasures.map(getMeasureWithUnit)}
                readableKeys={readableMeasures}
                showLegend
                showTooltip
                stacked
                timeSeriesGranularity={queryParamState.dateRangeGranularity}
                xAxisKey="timestamp"
              />
            </Box>
          </Flex>
        </InsightsSelector>
      </Grid>

      <Box
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_1}
        marginVertical={theme.space_lg}
        padding={theme.space_md}
      >
        <AzureStorageBBTableControls
          csvData={csvData}
          debouncedSearchText={debouncedSearchText}
          tableFilters={queryParamState.tableFilters}
          searchText={searchText}
          onInteraction={handleInteraction}
        />
      </Box>

      <Box width="100%" overflowX="auto">
        <AzureStorageBBTable
          data={filteredTableData}
          isLoading={isLoadingTableData}
          onInteraction={handleInteraction}
        />
      </Box>
    </Box>
  );
}

// COST OPTIONS
const costChartOptions = [
  AzureStorageBBCostChartOption.PROJECT_ID,
  AzureStorageBBCostChartOption.ACCOUNT_ID,
  AzureStorageBBCostChartOption.PRICING_MODEL,
  AzureStorageBBCostChartOption.STORAGE_ACCOUNT,
].map((costOption) => ({
  label: getOptionLabel(costOption),
  value: costOption,
}));

// USAGE OPTIONS
const usageChartOptions = [
  AzureStorageBBUsageChartOption.BLOB_SIZE,
  AzureStorageBBUsageChartOption.NETWORK,
  AzureStorageBBUsageChartOption.TRANSACTIONS,
  AzureStorageBBUsageChartOption.USAGE_AMOUNT,
].map((usageOption) => ({
  label: getOptionLabel(usageOption),
  value: usageOption,
}));

function getCostDimensionsFromOption(
  option: AzureStorageBBCostChartOption
): AzureStorageBBDimension[] {
  switch (option) {
    case AzureStorageBBCostChartOption.ACCOUNT_ID:
      return [azureStorageBBDimensions.accountId];
    case AzureStorageBBCostChartOption.PRICING_MODEL:
      return [azureStorageBBDimensions.pricingModel];
    case AzureStorageBBCostChartOption.PROJECT_ID:
      return [azureStorageBBDimensions.projectId];
    case AzureStorageBBCostChartOption.STORAGE_ACCOUNT:
      return [azureStorageBBDimensions.storageAccount];
    default: {
      const _exhaustiveCheck: never = option;
      return _exhaustiveCheck;
    }
  }
}

const measuresWithUnit: { name: string; unit: UnitType }[] = [
  { name: azureStorageBBMeasures.cost, unit: UnitType.CURRENCY },
  { name: azureStorageBBMeasures.absoluteCredits, unit: UnitType.CURRENCY },
  { name: azureStorageBBMeasures.usageAmount, unit: UnitType.STANDARD },
  { name: azureStorageBBMeasures.blobSizeBytes, unit: UnitType.BINARY_BYTES },
  { name: azureStorageBBMeasures.containerCount, unit: UnitType.STANDARD },
  { name: azureStorageBBMeasures.transactions, unit: UnitType.STANDARD },
  { name: azureStorageBBMeasures.bytesDownloaded, unit: UnitType.BYTES },
  { name: azureStorageBBMeasures.bytesUploaded, unit: UnitType.BYTES },
];

function getMeasureWithUnit(measure: string) {
  return (
    [...measuresWithUnit].find((other) => other.name === measure) ?? {
      name: measure,
      unit: UnitType.STANDARD,
    }
  );
}

type CSVData = {
  headers: { key: string; label: string }[];
  rows: Record<string, string | number>[];
};

const csvAccessors = [
  azureStorageBBDimensions.projectId,
  azureStorageBBDimensions.accountId,
  azureStorageBBDimensions.storageAccount,
  azureStorageBBDimensions.pricingModel,
  azureStorageBBMeasures.cost,
  azureStorageBBMeasures.absoluteCredits,
  azureStorageBBMeasures.usageAmount,
  azureStorageBBMeasures.blobSizeBytes,
  azureStorageBBMeasures.containerCount,
  azureStorageBBMeasures.transactions,
  azureStorageBBMeasures.bytesDownloaded,
  azureStorageBBMeasures.bytesUploaded,
] as const;

function getCSVData(data: AzureStorageBBDatum[]): CSVData {
  if (!data.length) {
    return { headers: [], rows: [] };
  }

  const rows = data.map((datum) => ({
    projectId: datum.projectId,
    accountId: datum.accountId,
    resourceId: datum.resourceId,
    storageAccount: datum.storageAccount,
    pricingModel: datum.pricingModel,
    skuDescription: datum.skuDescription,
    cost: datum.cost,
    absoluteCredits: datum.absoluteCredits,
    usageAmount: datum.usageAmount,
    blobSizeBytes: datum.blobSizeBytes,
    containerCount: datum.containerCount,
    transactions: datum.transactions,
    bytesDownloaded: datum.bytesDownloaded,
    bytesUploaded: datum.bytesUploaded,
  }));

  const headers = csvAccessors.map((csvAccessor) => {
    // ensure rows has a value for each accessor
    const key: keyof (typeof rows)[number] = csvAccessor;

    // ensure copyText has a value for each accessor
    const copyTextKey: keyof typeof copyText = `azureStorageBBTableHeader_${csvAccessor}`;
    const label = copyText[copyTextKey];

    return { key, label };
  });

  return { headers, rows };
}

type GetFilteredBBDataParams = {
  data: AzureStorageBBDatum[];
  tableFilters: AzureStorageBBTableFilters;
  searchText: string | null;
};

function getFilteredBBData(
  params: GetFilteredBBDataParams
): AzureStorageBBDatum[] {
  const searchText = (params.searchText ?? "").toLowerCase().trim();

  return params.data.filter((datum) => {
    if (!isDatumPassingFilters(datum, params.tableFilters)) {
      return false;
    }

    if (!isSearchTextInDatum(datum, searchText)) {
      return false;
    }

    return true;
  });
}

function getChartQueryFilters(tableFilters: AzureStorageBBTableFilters) {
  const queryFilters: QueryFilter[] = [];

  [
    azureStorageBBDimensions.accountId,
    azureStorageBBDimensions.pricingModel,
    azureStorageBBDimensions.projectId,
    azureStorageBBDimensions.resourceId,
    azureStorageBBDimensions.skuDescription,
    azureStorageBBDimensions.storageAccount,
  ].forEach((key) => {
    const filterValue = tableFilters[key];

    if (filterValue === null) return;

    if (filterValue === "") {
      queryFilters.push({
        name: key,
        operator: Operator.NOT_SET,
      });

      return;
    }

    queryFilters.push({
      name: key,
      operator: Operator.EQUALS,
      values: [filterValue],
    });
  });

  return queryFilters;
}

function getOptionLabel(
  option: AzureStorageBBCostChartOption | AzureStorageBBUsageChartOption
) {
  const copyTextKey: keyof typeof copyText = `azureStorageBBChartOptionLabel_${option}`;

  return copyText[copyTextKey];
}

function getQueryParamState(queryParams: QueryParams): QueryParamState {
  const dateRange =
    queryParams.date_range_start && queryParams.date_range_end
      ? [queryParams.date_range_start, queryParams.date_range_end]
      : getDateRangeFromDurationType(queryParams.duration);

  const dateRangeDurationInHours =
    dateRange[0] && dateRange[1]
      ? differenceInHours(endOfDay(dateRange[1]), dateRange[0])
      : 0;

  const isSmallDateRange =
    dateRangeDurationInHours > 0 && dateRangeDurationInHours <= 48;

  const dateRangeGranularity = isSmallDateRange
    ? TimeGranularity.HOUR
    : TimeGranularity.DAY;

  const selectedUsageChartUtilization: string[] = [];

  (queryParams.selected_usage_chart_utilization ?? []).forEach((value) => {
    if (!value) return;
    selectedUsageChartUtilization.push(value);
  });

  return {
    dateRange,
    dateRangeGranularity,
    duration: queryParams.duration,
    tableFilters: queryParams.filters ?? tableFiltersDefault,
    selectedCostChartOption: queryParams.selected_cost_chart_option,
    selectedGroup: queryParams.selected_group ?? null,
    selectedUsageChartOption: queryParams.selected_usage_chart_option,
  };
}

const readableMeasures = {
  productVCPUChart: "productVCPU",
  productMemoryBytesChart: "productMemoryBytes",
};

function getUsageMeasuresFromOption(
  option: AzureStorageBBUsageChartOption
): AzureStorageBBMeasure[] {
  switch (option) {
    case AzureStorageBBUsageChartOption.BLOB_SIZE: {
      return [azureStorageBBMeasures.blobSizeBytes];
    }
    case AzureStorageBBUsageChartOption.NETWORK: {
      return [
        azureStorageBBMeasures.bytesDownloaded,
        azureStorageBBMeasures.bytesUploaded,
      ];
    }
    case AzureStorageBBUsageChartOption.TRANSACTIONS: {
      return [azureStorageBBMeasures.transactions];
    }
    case AzureStorageBBUsageChartOption.USAGE_AMOUNT: {
      return [azureStorageBBMeasures.usageAmount];
    }
    default: {
      const _exhaustiveCheck: never = option;
      return _exhaustiveCheck;
    }
  }
}

function isDatumPassingFilters(
  datum: AzureStorageBBDatum,
  filters: AzureStorageBBTableFilters
): boolean {
  const filterKeys = [
    azureStorageBBDimensions.accountId,
    azureStorageBBDimensions.pricingModel,
    azureStorageBBDimensions.projectId,
    azureStorageBBDimensions.resourceId,
    azureStorageBBDimensions.skuDescription,
    azureStorageBBDimensions.storageAccount,
  ];

  for (const key of filterKeys) {
    const filterValue = filters[key];

    if (
      filterValue !== null &&
      datum[key].toLowerCase().trim() !== filterValue.toLowerCase().trim()
    ) {
      return false;
    }
  }

  return true;
}

function isSearchTextInDatum(
  datum: AzureStorageBBDatum,
  searchText: string
): boolean {
  if (searchText === "") return true;

  const values = [
    datum.accountId,
    datum.pricingModel,
    datum.projectId,
    datum.storageAccount,
  ].map((value) => (value === "" ? "null" : value));

  return values.some((value) =>
    value.toLowerCase().trim().includes(searchText)
  );
}
